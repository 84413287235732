import { Rating } from "@mui/material";
import React from "react";

const GoogleReviewCard = ({ info }) => {
  // console.log("🚀 ~ GoogleReviewCard ~ info:", info);
  return (
    <div className="px-3.5 py-[21px] bg-[#F0F0F0] rounded-[7px] h-full">
      <div className="flex items-center gap-4 mb-5">
        <div className="flex-shrink-0 w-8 h-9 md:h-16 md:w-16 rounded-full overflow-hidden">
          <img
            src={info?.user_profile || "/images/customer1.jpeg"}
            alt=""
            loading="lazy"
          />
        </div>
        <div>
          <h4 className="xs:text-lg md:text-[21px] font-semibold !leading-[1.1] line-clamp-1 mb-1.5">
            {info?.user_name}
          </h4>
          <div className="flex flex-wrap items-center gap-2">
            <Rating readOnly value={info?.product_review_star} />
            <p className="text-sm !leading-tight text-[#8D8C8C]">
              {info?.date}
            </p>
          </div>
        </div>
      </div>
      <p className="sm:text-[19px] font-normal">
        {info?.product_review_message}
      </p>
    </div>
  );
};

export default GoogleReviewCard;
