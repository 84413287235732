import React from "react";

const Notification = ({ customClass }) => {
  return (
    <div className="flex items-start gap-2 xs:gap-4 xl:gap-6 p-3 sm:p-4 lg:p-[26px] border-b border-[#BFBFBF]">
      <div className="flex-shrink-0 h-10 w-10 sm:h-20 sm:w-20 rounded-full overflow-hidden">
        <img
          src="/images/products/blackcholi.jpeg"
          className="h-full w-full object-cover object-top"
          alt=""
          loading="lazy"
        />
      </div>
      <div>
        <h4 className="xs:text-lg md:text-xl xl:text-[25px] !leading-[1.1] line-clamp-1 mb-1.5">
          Pink Embroidered Art Silk Umbrella Lehenga a vision of elegance...
        </h4>
        <p className="sm:text-lg md:text-xl xl:text-2xl !leading-none text-[#737373]">
          <span>15/05/2023</span> <span>01:55 PM</span>
        </p>
        <div
          className={`flex flex-wrap gap-2 xs:gap-3 md:gap-5 mt-4 md:mt-6 ${customClass}`}
        >
          <div>
            <img
              className="flex-shrink-0 w-20 h-20 xs:w-28 xs:h-24 md:w-44 md:h-40 object-cover object-top"
              src="/images/products/blackcholi.jpeg"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <img
              className="flex-shrink-0 w-20 h-20 xs:w-28 xs:h-24 md:w-44 md:h-40 object-cover object-top"
              src="/images/products/blackcholi.jpeg"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <img
              className="flex-shrink-0 w-20 h-20 xs:w-28 xs:h-24 md:w-44 md:h-40 object-cover object-top"
              src="/images/products/blackcholi.jpeg"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
