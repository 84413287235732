import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumb from "../components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { STORAGE } from "../config/config";
import { HiShoppingCart } from "react-icons/hi2";
import { Circles } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import Header from "../components/Header";

const MyOrderpage = () => {
  const [orders, setOrders] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ordersPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
      try {
        const response = await axios.post("orders", {
          device_id: localStorage.getItem(STORAGE?.DEVICEID),
          user_id: userProfile?.id,
          limit: ordersPerPage.toString(),
          page: currentPage.toString(),
          is_admin: "0",
        });
        // console.log("API Response", response.data); // Log the entire API response
        if (response.data.STATUS === 200) {
          setOrders(response.data.DATA.data);
          setTotalPages(response.data.DATA.last_page);
        } else {
          console.error("Failed to fetch order data");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentPage]);

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      My Orders
    </p>,
  ];

  const getStatusStyle = (status) => {
    switch (status) {
      case "pending":
        return "bg-[#FFF8E5] text-[#FFC700]";
      case "complete":
        return "bg-[#E3FFED] text-[#00C242]";
      case "shipped":
        return "bg-blue-200 text-blue-800";
      case "cancel":
        return "bg-[#FFE9E9] text-[#FF0F0F]";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/orders-details/${orderId}`);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div class="max-w-[1600px] mx-auto px-3 mt-6 mb-6">
        {orders.length === 0 ? (
          <div className="grid justify-center items-center md:flex md:justify-center gap-2 text-sm md:text-xl font-bold text-slate-500 bg-[#eeeeee] p-10 md:p-40 ">
            <div className="flex justify-center">
              <HiShoppingCart size="30px" className="text-dark-400" />
            </div>
            <div className="text-center">
              You have not ordered anything yet.
            </div>
          </div>
        ) : (
          <>
            {orders.slice(0, showAll ? orders.length : 3).map((order) => (
              <div
                className="mb-[10px]"
                key={order.order_id}
                onClick={() => handleOrderClick(order.order_id)}
              >
                <div className="border-solid border-2 border-gray-200 cursor-pointer">
                  <div className="flex justify-between items-center p-4">
                    <div className="flex gap-3  sm:gap-6 items-center">
                      <div>
                        <div className="text-[8px] sm:text-[18px] font-bold">
                          ORDER DATE
                        </div>
                        <div className="text-[7px] sm:text-[16px]">
                          {order.order_date}
                        </div>
                      </div>
                      <div>
                        <div className="text-[8px] sm:text-[18px] font-bold">
                          ORDER TOTAL
                        </div>
                        <div className="text-[7px] sm:text-[16px]">
                          ₹{order.order_net_amount.toFixed(2)} (
                          {order.total_quantity} item
                          {order.total_quantity > 1 ? "s" : ""})
                        </div>
                      </div>
                      <div>
                        <div className="text-[8px] sm:text-[18px] font-bold">
                          EXPECTED DELIVERY DATE
                        </div>
                        <div className="text-[7px] sm:text-[16px]">
                          {order.aspected_delivered_time}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="text-[7px] sm:text-[16px] text-end">
                          {order.order_number}
                        </div>
                        <div
                          className={`text-center w-[80px] text-[7px] md:text-sm rounded-[4px] p-1 ${getStatusStyle(
                            order.order_status
                          )}`}
                        >
                          {order.order_status.charAt(0).toUpperCase() +
                            order.order_status.slice(1)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {!showAll && orders.length > 3 && (
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowAll(true)}
                  className="px-4 py-2 text-black font-bold"
                >
                  Show All
                </button>
              </div>
            )}
            {showAll && (
              <Stack spacing={2} alignItems="center" className="my-6">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  variant="outlined"
                  shape="rounded"
                  nextIconButtonText="Next"
                  nextIconButtonProps={{
                    sx: {
                      backgroundColor: "blue",
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      border: "none",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#5C5C5C !important",
                      color: "#fff",
                    },
                    "& .MuiButtonBase-root": {
                      borderRadius: "0",
                      height: "40px",
                      width: "40px",
                      fontSize: "20px",
                    },
                  }}
                  onChange={handlePageChange}
                />
              </Stack>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyOrderpage;
