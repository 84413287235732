export const STORAGE = {
    USERDETAIL:'userProfile',
    CARTDETAIL:'cartDetail',
    ISLOGIN:"isLoggedIn",
    DEVICEID:"deviceId",
    B2C: "btoc",
    B2B: "btob",
    MOBILE:"mobile",
    GOOGLE:"google",
    FACEBOOK:"facebook",
    WISHLIST_COUNT: 'wishlistCount',
}