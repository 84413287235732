import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
const ProductImageSlider = ({ imageList }) => {
  const id = Date.now();
  const nextClassName = `swiper-button-next-${id}`;
  const prevClassName = `swiper-button-prev-${id}`;
  return (
    <div className="relative h-full">
      <button
        className={`absolute top-1/2 left-2 -translate-y-1/2 z-10 w-[36px] h-[36px] rounded-full bg-[#EDEDED] flex items-center justify-center outline-none opacity-0 group-hover:opacity-100  ${nextClassName}`}
      >
        <FaAngleLeft className="text-lg" />
      </button>
      <button
        className={`absolute top-1/2 right-2 -translate-y-1/2 z-10 w-[36px] h-[36px] rounded-full bg-[#EDEDED] flex items-center justify-center outline-none opacity-0 group-hover:opacity-100 ${prevClassName}`}
      >
        <FaAngleRight className="text-lg" />
      </button>
      <Swiper
        className="relative h-full product-image-slider"
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides
        navigation={{
          nextEl: "." + prevClassName,
          prevEl: "." + nextClassName,
        }}
        pagination={{
          clickable: false,
        }}
        modules={[Navigation, Pagination]}
        loop
        autoplay={{
          delay: 1000,
        }}
      >
        {imageList?.map((image, index) => {
          return (
            <SwiperSlide
              key={"productImage-" + index}
              className="h-full overflow-hidden"
            >
              <div className="relative h-full w-full overflow-hidden">
                <img
                  src={image}
                  className="h-full w-full object-cover transition-transform duration-500 hover:scale-110"
                  alt=""
                  loading="lazy"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ProductImageSlider;
