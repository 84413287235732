import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import SuggestionCard from "./SuggestionCard";

const SuggestionBlock = ({ title, info }) => {
  const id = Date.now();
  const nextClassName = `swiper-button-next-${id}`;
  const prevClassName = `swiper-button-prev-${id}`;
  return (
    <div className="max-w-[1836px] mx-auto  my-5">
      <h3 className="mb-5 px-3 font-jost text-3xl font-normal leading-none tracking-normal text-black">
        {title || "You May Also Like"}
      </h3>
      <div className="relative lg:px-[27px]">
        <button
          className={`hidden lg:flex absolute top-1/2 left-1 -translate-y-1/2 z-10 w-[46px] h-[46px] rounded-full bg-[#EDEDED] items-center justify-center outline-none ${prevClassName}`}
        >
          <FaAngleLeft className="text-lg" />
        </button>
        <button
          className={`hidden lg:flex absolute top-1/2 right-1 -translate-y-1/2 z-10 w-[46px] h-[46px] rounded-full bg-[#EDEDED] items-center justify-center outline-none ${nextClassName}`}
        >
          <FaAngleRight className="text-lg" />
        </button>
        <Swiper
          className="relative"
          spaceBetween={16} 
          slidesPerView={1.2}
          centeredSlides
          navigation={{
            nextEl: "." + nextClassName,
            prevEl: "." + prevClassName,
          }}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            340: {
              slidesPerView: 1.5,
              centeredSlides: true,
            },
            540: {
              slidesPerView: 2,
            },
            650: {
              slidesPerView: 3.5,
            },
            992: {
              slidesPerView: 4,
              centeredSlides: false,
            },
            1200: {
              slidesPerView: 5,
              centeredSlides: false,
            },
            1400: {
              slidesPerView: 5,
              spaceBetween: 25,
              centeredSlides: false,
            },
            1600: {
              slidesPerView: 6,
              spaceBetween: 25,
              centeredSlides: false,
            },
          }}
          
        >
          {info?.map((item, index) => {
            return (
              <SwiperSlide
                key={"suggestion" + item?.id}
                className="!h-auto py-[10px]"
              >
                <SuggestionCard
                  imageUrl={item?.product_image?.[0]}
                  info={item}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default SuggestionBlock;
