import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const MegaMenuContent = ({
  variation,
  variationKeys,
  maxColumns,
  usedColumns,
  category_image,
  setShouldReload,
  menuVisible,
  handleNavigate,
  setImageError,
  navigate,
}) => {
  return (
    <>
      <div className="absolute top-full left-0 right-0 mx-auto translate-y-5 opacity-0 invisible group-hover:translate-y-0 group-hover:opacity-100 group-hover:visible transition-all max-h-[calc(100vh-146px)] h-fit">
        <div
          className="bg-white p-5 py-10 xl:px-8 2xl:px-[76px] text-start"
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${usedColumns}, 1fr)`,
            width: `calc(${usedColumns} * 20%)`,
          }}
        >
          {variationKeys.slice(0, maxColumns - 1).map((key) => (
            <div className="px-3" key={key}>
              <h4
                className={`text-base xl:text-sm 2xl:text-lg leading-[30px] font-semibold text-black uppercase pb-5 ${
                  menuVisible ? "slide-in" : ""
                }`}
              >
                {key}
              </h4>
              {Array.isArray(variation[key]) ? (
                <ul className={menuVisible ? "slide-in" : ""}>
                  {variation[key].slice(0, 5).map((item) => (
                    <li key={item.id}>
                      <Link
                        to="javascript:void(0);"
                        onClick={() => {
                          navigate("/product-page", {
                            state: {
                              category: [item?.id],
                            },
                          });

                          setShouldReload(true);
                        }}
                        className="animated-list-item text-base xl:text-xs 2xl:text-lg 3xl:leading-10 font-normal text-black capitalize"
                      >
                        {key === "4"
                          ? item?.category_name.replace(/\s/g, "")
                          : item?.category_name}
                      </Link>
                    </li>
                  ))}
                  {variation[key].length > 5 && (
                    <li>
                      <Link
                        to="javascript:void(0);"
                        onClick={() => handleNavigate(variation[key])}
                        className="animated-list-item text-base xl:text-xs 2xl:text-lg 3xl:leading-10 font-bold text-black capitalize"
                      >
                        See All
                      </Link>
                    </li>
                  )}
                </ul>
              ) : (
                <p>{variation[key]}</p>
              )}
            </div>
          ))}
          <div className="max-w-full w-full h-full">
            <img
              src={category_image.category_image}
              className="h-full w-full object-cover"
              alt=""
              onError={() => setImageError(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MegaMenuContent;
