import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import toast from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";

const ReactPlayer = lazy(() => import("react-player"));
const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));
const Breadcrumb = lazy(() => import("../components/Breadcrumb"));

const AboutUsPage = () => {
  const [aboutInfo, setAboutInfo] = useState();
  const [loading, setLoading] = useState(true);
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      About Us
    </p>,
  ];

  const fetchInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("about_us");
      if (data && data?.STATUS === 200) {
        setAboutInfo(data?.DATA?.[0]);
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.MESSAGE ||
          err?.message ||
          "Failed to fetch information."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us - Kapoorlehengasaree</title>
        <meta property="og:title" content="About us" />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="You can buy everything from bridal lehenga to designer sarees from Kapoor lehenga saree at the best price"
        />
        <meta
          property="og:url"
          content="https://kapoorlehengasaree.com/about-us"
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="About us" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="You can buy everything from bridal lehenga to designer sarees from Kapoor lehenga saree at the best price"
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/about-us" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <>
            <div className="loader-overlay">
              <div className="loader-container">
                <div className="loader-circle-9">
                  Kapoor
                  <span></span>
                </div>
              </div>
            </div>
          </>
        }
      >
        <TopBar />
        <Header />
        <Breadcrumb list={breadcrumbArray} />
        <div className="max-w-[1608px] mx-auto px-3 mt-6 mb-10 md:mb-20 xl:mb-[100px]">
          <div className="max-w-[1216px] aspect-[16/9] mx-auto mb-8 md:mb-[52px]">
            <ReactPlayer
              url={aboutInfo?.video_url}
              controls
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className="mb-4 md:mb-6">
            <img
              src="/images/logo-gold.png"
              className="w-[200px] mx-auto"
              alt="Logo"
            />
          </div>
          <p className="text-xl md:text-2xl lg:text-xl xl:text-3xl 2xl:text-4xl font-normal !leading-[1.23] text-black font-jost mb-6 md:mb-11">
            {aboutInfo?.description}
          </p>
          <div className="flex flex-wrap -mx-3 gap-y-5 lg:gap-y-11">
            <div className="w-full sm:w-6/12 px-3">
              <Link
                to="/about-company"
                className="flex items-center max-w-[665px] bg-[#F4F4F4] p-3 md:p-4 xl:p-5 rounded-lg gap-3 md:gap-5 xl:gap-7"
              >
                <div className="flex-shrink-0">
                  <img
                    src="/images/company.png"
                    className="h-14 lg:h-auto mx-auto"
                    alt="Company"
                    loading="lazy"
                  />
                </div>
                <div className="flex items-center justify-between flex-1">
                  <div>
                    <h3 className="text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-none font-jost font-medium text-black mb-2">
                      About Company
                    </h3>
                    <p className="xs:text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-[1.12] font-jost text-[#4D4D4D] line-clamp-1">
                      This exquisite saree seamlessly...
                    </p>
                  </div>
                  <span className="">
                    <IoIosArrowForward className="text-2xl lg:text-[38px]" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="w-full sm:w-6/12 px-3">
              <Link
                to="/success-stories"
                className="flex items-center max-w-[665px] bg-[#F4F4F4] p-3 md:p-4 xl:p-5 rounded-lg gap-3 md:gap-5 xl:gap-7"
              >
                <div className="flex-shrink-0">
                  <img
                    src="/images/success.png"
                    className="h-14 lg:h-auto mx-auto"
                    alt="Success Stories"
                    loading="lazy"
                  />
                </div>
                <div className="flex items-center justify-between flex-1">
                  <div>
                    <h3 className="text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-none font-jost font-medium text-black mb-2">
                      Success Stories
                    </h3>
                    <p className="xs:text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-[1.12] font-jost text-[#4D4D4D] line-clamp-1">
                      Shopping for ethnic wear online...
                    </p>
                  </div>
                  <span className="">
                    <IoIosArrowForward className="text-2xl lg:text-[38px]" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="w-full sm:w-6/12 px-3">
              <Link
                to="/team-detail"
                className="flex items-center max-w-[665px] bg-[#F4F4F4] p-3 md:p-4 xl:p-5 rounded-lg gap-3 md:gap-5 xl:gap-7"
              >
                <div className="flex-shrink-0">
                  <img
                    src="/images/detail.png"
                    className="h-14 lg:h-auto mx-auto"
                    alt="Team Details"
                    loading="lazy"
                  />
                </div>
                <div className="flex items-center justify-between flex-1">
                  <div>
                    <h3 className="text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-none font-jost font-medium text-black mb-2">
                      Team Details
                    </h3>
                    <p className="xs:text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-[1.12] font-jost text-[#4D4D4D] line-clamp-1">
                      Indian weddings are known for...
                    </p>
                  </div>
                  <span className="">
                    <IoIosArrowForward className="text-2xl lg:text-[38px]" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="w-full sm:w-6/12 px-3">
              <Link
                to="/our-certificate"
                className="flex items-center max-w-[665px] bg-[#F4F4F4] p-3 md:p-4 xl:p-5 rounded-lg gap-3 md:gap-5 xl:gap-7"
              >
                <div className="flex-shrink-0">
                  <img
                    src="/images/certificate-of-origin.png"
                    className="h-14 lg:h-auto mx-auto"
                    alt="Certificates"
                    loading="lazy"
                  />
                </div>
                <div className="flex items-center justify-between flex-1">
                  <div>
                    <h3 className="text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-none font-jost font-medium text-black mb-2">
                      Certificates
                    </h3>
                    <p className="xs:text-lg md:text-xl xl:text-2xl 2xl:text-3xl !leading-[1.12] font-jost text-[#4D4D4D] line-clamp-1">
                      Wearing Indian ethnic wear is...
                    </p>
                  </div>
                  <span className="">
                    <IoIosArrowForward className="text-2xl lg:text-[38px]" />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </Suspense>
    </>
  );
};

export default AboutUsPage;
