import axios from "axios";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import WishlistProductCard from "../components/WishlistProductCard";
import toast from "react-hot-toast";
import { STORAGE } from "../config/config";
import Header from "../components/Header";
import { Circles } from "react-loader-spinner";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";

const WishlistPage = () => {
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLoggedIn = localStorage.getItem(STORAGE?.ISLOGIN);

  const breadcrumbArray = [
    <Link
      to={"/"}
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md  !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md  !leading-[1.16] font-normal font-jost  capitalize"
    >
      Wishlist
    </p>,
  ];

  // const fetchWishlistProducts = async () => {
  //   const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
  //   try {
  //     const { data } = await axios.post("mywishlist", {
  //       device_id: localStorage.getItem(STORAGE?.DEVICEID),
  //       is_mobile: "0",
  //       user_id: userProfile?.id,
  //       user_type: userProfile?.user_type,
  //       page: 1,
  //     });

  //     if (data && data?.STATUS === 200) {
  //       setWishlistProducts(data?.DATA);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     toast.error(err?.response?.data?.MESSAGE || "Something went wrong");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchWishlistProducts = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    
    // Check if the user is logged in and if the userProfile exists
    if (!userProfile || !userProfile?.id) {
      setLoading(false); // Stop the loader if no userProfile exists
      return;
    }
  
    try {
      const { data } = await axios.post("mywishlist", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        is_mobile: "0",
        user_id: userProfile?.id,
        user_type: userProfile?.user_type,
        page: 1,
      });

      // console.log("wishagsh ln 120")
  
      if (data && data?.STATUS === 200) {
        setWishlistProducts(data?.DATA);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  
  const removeFromWishlist = async (id) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("removefromwishlist", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        product_id: [id],
        user_id: userProfile?.id,
      });

      if (data && data?.STATUS === 200) {
        fetchWishlistProducts();
        toast.success(data?.MESSAGE || "Removed from wishlist.");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     fetchWishlistProducts();
  //   } else {
  //     setWishlistProducts([]);
  //     setLoading(false);
  //   }
  //   localStorage.setItem(STORAGE?.WISHLIST_COUNT, wishlistProducts.length);
  // }, [isLoggedIn, wishlistProducts]);


  useEffect(() => {
    if (isLoggedIn) {
      fetchWishlistProducts();
    } else {
      setWishlistProducts([]);
      setLoading(false);
    }

    // Update wishlist count in localStorage only once
    if (wishlistProducts.length > 0) {
      localStorage.setItem(STORAGE?.WISHLIST_COUNT, wishlistProducts.length);
    }
  }, [isLoggedIn]); // Removed wishlistProducts from the dependency array

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div className="max-w-[1810px] w-full px-[15px] mx-auto pb-10">
        <div className="my-5 lg:mb-[30px]">
          <div className="flex items-center gap-3">
            <h3 className="text-lg lg:text-xl xl:text-3xl !leading-none font-medium ">
              My Wishlist
            </h3>
            <span className="text-base lg:text-xl leading-none font-normal text-[#555555]">
              {wishlistProducts?.length} products
            </span>
          </div>
        </div>
        {loading ? (
          <div className="w-[100%] h-[60vh] flex justify-center items-center">
            <Circles
              height="30"
              width="30"
              color="#e9b159"
              ariaLabel="loading"
            />
          </div>
        ) : wishlistProducts.length === 0 ? (
          <div className="bg-[#eeeeee] h-[280px] flex justify-center items-center">
            <div className="w-full text-center text-lg lg:text-xl xl:text-2xl text-[#555555]">
              Your wishlist is currently empty.
            </div>
          </div>
        ) : (
          <div className="flex items-center flex-wrap -mx-[15px] gap-y-[18px]">
            {wishlistProducts.map((item, index) => {
              return (
                <div
                  key={index}
                  className="px-[10px] new-arrival-card w-full xs:w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/6"
                >
                  <WishlistProductCard
                    data={item}
                    removeFromWishlist={removeFromWishlist}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default WishlistPage;
