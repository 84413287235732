import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SubscribeNewLetterPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Subscribe to New Letter
    </p>,
  ];
  return (
    <>
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div className="bg-[#EEEEEE]">
        <div className="w-full max-w-[1836px] mx-auto">
          <img
            src="/images/subscribe-new-letter-img.png"
            className="w-full"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="max-w-[1644px] mx-auto px-3">
          <div className="px-5 md:px-10 xl:px-[90px] pt-5 sm:pt-[34px] pb-10 md:pb-20 xl:pb-[105px] bg-white -mt-8 md:-mt-16 lg:-mt-24 2xl:-mt-[180px] relative">
            <h3 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl font-medium !leading-[1.4] text-[#242424] font-jost max-w-[982px] mb-5">
              Pongal Extravaganza: A Fusion of Festivities and Fashion
            </h3>
            <p className="text-lg md:text-2xl !leading-[1.23] font-jost text-[#4d4d4d] mb-5 lg:mb-[30px]">
              The Navy Blue Banarasi Silk Bandhani Woven Saree is a mesmerizing
              piece of traditional Indian craftsmanship and elegance. This
              exquisite saree The Navy Blue Banarasi Silk Bandhani Woven Saree
              is a mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree Banarasi Silk Bandhani Woven Saree is a mesmerizing piece of
              traditional Indian craftsmanship and elegance. This exquisite
              saree The Navy Blue Banarasi Silk Bandhani Woven Saree is a
              mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree...
            </p>
            <ul className="list-disc ps-[18px]">
              <li>
                <h5 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-[1.23] font-semibold font-jost text-[#000] mb-5 lg:mb-[30px]">
                  Top Six Famous Winter Wedding Clothes Ideas to Slay the Look
                </h5>
              </li>
            </ul>
            <div className="w-full mb-5 lg:mb-[30px]">
              <img
                src="/images/pongal-extravaganza.png"
                className="w-full"
                alt=""
                loading="lazy"
              />
            </div>
            <p className="text-lg md:text-2xl !leading-[1.23] font-jost text-[#4d4d4d] mb-5 lg:mb-[30px]">
              The Navy Blue Banarasi Silk Bandhani Woven Saree is a mesmerizing
              piece of traditional Indian craftsmanship and elegance. This
              exquisite saree The Navy Blue Banarasi Silk Bandhani Woven Saree
              is a mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree Banarasi Silk Bandhani Woven Saree is a mesmerizing piece of
              traditional Indian craftsmanship and elegance. This exquisite
              saree The Navy Blue Banarasi Silk Bandhani Woven Saree is a
              mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree...
            </p>
            <ul className="list-disc ps-[18px]">
              <li>
                <h5 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-[1.23] font-semibold font-jost text-[#000] mb-5 lg:mb-[30px]">
                  Top Six Famous Winter Wedding Clothes Ideas to Slay the Look
                </h5>
              </li>
            </ul>
            <div className="w-full mb-5 lg:mb-[30px]">
              <img
                src="/images/pongal-extravaganza-2.png"
                className="w-full"
                alt=""
                loading="lazy"
              />
            </div>
            <p className="text-lg md:text-2xl !leading-[1.23] font-jost text-[#4d4d4d]">
              The Navy Blue Banarasi Silk Bandhani Woven Saree is a mesmerizing
              piece of traditional Indian craftsmanship and elegance. This
              exquisite saree The Navy Blue Banarasi Silk Bandhani Woven Saree
              is a mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree Banarasi Silk Bandhani Woven Saree is a mesmerizing piece of
              traditional Indian craftsmanship and elegance. This exquisite
              saree The Navy Blue Banarasi Silk Bandhani Woven Saree is a
              mesmerizing piece of traditional Indian craftsmanship and
              elegance. This exquisite saree Woven Saree is a mesmerizing piece
              of traditional Indian craftsmanship and elegance. This exquisite
              saree...
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubscribeNewLetterPage;
