import {
  Accordion,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";
import { MdOutlineFilterList } from "react-icons/md";

const ProductFilter = ({
  handleFilterClick,
  handleFilterChange,
  selectedFilters,
  handleCheckboxLocalChange,
  showFilterOverlay,
  filterOptions,
  activeFilterIndex,
  handleCloseClick,
  handleApplyFilters,
}) => {
  return (
    <>
      <div className="block lg:hidden fixed bottom-0 w-full px-3 bg-[#e9b15a] z-50">
        <div className="flex justify-center items-center gap-1 text-xl md:text-2xl md:leading-5 font-medium font-jost text-center p-3">
          <div>
            <MdOutlineFilterList />
          </div>
          <div
            className="text-center text-md uppercase"
            onClick={handleFilterClick}
          >
            filter
          </div>
        </div>
      </div>

      {showFilterOverlay && (
        <div className="block lg:hidden fixed inset-0 z-[1002] bg-white flex flex-col">
          <div className="text-xl font-medium font-jost p-3 border-b border-solid border-[#dcdcdc]">
            FILTER BY
          </div>

          <div className="flex-grow flex">
            <div className="w-1/4 border-r border-solid border-[#dcdcdc] bg-[#F6F6F6]">
              {filterOptions?.map((filterdata, index) => {
                if (filterdata.name.toLowerCase() !== "sort") {
                  return (
                    <div
                      key={index}
                      className={`p-3 border-b border-solid border-[#dcdcdc] cursor-pointer capitalize  ${
                        activeFilterIndex === index ? "font-bold bg-white" : ""
                      }`}
                      onClick={() => handleFilterChange(index)}
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {filterdata.title}
                    </div>
                  );
                }
                return null;
              })}
            </div>

            <div className="w-3/4 p-3">
              {filterOptions?.map((filterdata, index) => {
                if (
                  filterdata.name.toLowerCase() !== "sort" &&
                  activeFilterIndex === index
                ) {
                  const sortedItems = filterdata.data?.sort((a, b) => {
                    const isCheckedA = selectedFilters[
                      filterdata.name
                    ]?.includes(a.value);
                    const isCheckedB = selectedFilters[
                      filterdata.name
                    ]?.includes(b.value);
                    return isCheckedB - isCheckedA;
                  });

                  return (
                    <div key={index}>
                      <Accordion
                        key={index}
                        className="!shadow-none !border-0 !border-b border-[#dcdcdc] rounded-none my-0 accordion-main"
                        expanded={true}
                      >
                        <AccordionDetails className=" p-4">
                          <FormGroup className="accordion-list max-h-60 overflow-y-auto !flex-nowrap">
                            {sortedItems?.map((item, itemIndex) => (
                              <FormControlLabel
                                onClick={() =>
                                  handleCheckboxLocalChange(
                                    filterdata.name,
                                    item.value
                                  )
                                }
                                key={item.value + itemIndex}
                                className="text-base leading-5 font-jost text-black capitalize"
                                control={
                                  <Checkbox
                                    checked={
                                      selectedFilters[filterdata.name]?.indexOf(
                                        item.value
                                      ) >= 0
                                    }
                                    style={{ color: "#E9B159" }}
                                  />
                                }
                                label={item.label}
                              />
                            ))}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          <div className="flex justify-center p-2 space-x-4 border-t border-solid border-[#dcdcdc]">
            <button
              className="text-xl font-medium font-jost"
              onClick={handleCloseClick}
            >
              Close
            </button>
            <button
              className="text-xl font-medium font-jost  text-[#e9b15a]"
              onClick={handleApplyFilters}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductFilter;
