import React from "react";
import { Link } from "react-router-dom";

const PopularSearchSection = ({ data }) => {
  return (
    <section className="mt-[23px] mb-[30px]">
      <div className="max-w-[1804px] mx-auto px-3 text-center">
        <h2 className="text-xl leading-none font-bold font-jost from-black mb-4 md:mb-[30px]">
          POPULAR SEARCHES
        </h2>
        <div className="flex flex-wrap justify-center gap-x-1">
          {data?.POPULAR_SEARCH?.map((item, index) => {
            return (
              <Link
                to="/product-page"
                className="text-[13px] leading-[17px] font-normal font-jost text-black"
                // key={"popularSearch" + index}
              >
                {item} |
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PopularSearchSection;
