import React from "react";

const TeamCard = ({ customClass, info }) => {
  return (
    <div className={`flex flex-wrap -mx-3 gap-y-4 ${customClass}`}>
      <div className="w-full  max-w-[250px] px-3">
        <img src={info?.image} className="" alt="" loading="lazy" />
      </div>
      <div className="w-full sm:w-3/5 flex-1 px-3">
        <p className="sm:text-2xl font-normal !leading-[1.23] text-black font-jost mb-6">
          {info?.name} <br />
          {info?.position}
        </p>
        <p className="sm:text-xl font-normal !leading-[1.23] text-black font-jost ">
          {info?.description}
        </p>
      </div>
    </div>
  );
};

export default TeamCard;
