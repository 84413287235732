import React, { useState, useEffect } from "react";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { InputBase } from "@mui/material";
import OTPInput from "../components/OtpDelete";
import Breadcrumb from "../components/Breadcrumb";

const DeleteUserAccountPage = () => {
  const [loading, setLoading] = useState(false);
  const [loginShowotp, setLoginShowotp] = useState(false); // Track OTP form visibility
  const [timer, setTimer] = useState(59);
  const [isCounting, setIsCounting] = useState(false);
  const [isOtpExpired, setIsOtpExpired] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Modal visibility state

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Delete Account
    </p>,
  ];

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({});

  const handleLoginSendOtp = async (payload) => {
    const loadingId = toast.success("Sending OTP...");

    try {
      const { data } = await axios.post("palystoreuserdelete", { ...payload });
      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "OTP sent.");
        setLoginShowotp(true); // Show OTP form
        setOtpValue(data?.OTP);
        // console.log(data?.OTP)
        setMobileNumber(payload.user_mobile);
        setTimer(59);
        setIsCounting(true);
        setIsOtpExpired(false);
      } else {
        toast.error(data?.MESSAGE || "Failed to send OTP.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      toast.dismiss(loadingId);
    }
  };

  const handleSubmitOtp = async () => {
    if (isOtpExpired) {
      toast.error("OTP has expired. Please request a new OTP.");
      return;
    }

    if (otpValue.length !== 6) {
      toast.error("Please enter OTP.");
      return;
    }

    const loadingId = toast.loading("Verifying OTP...");

    try {
      const { data } = await axios.post("palystore_otpverify", {
        user_mobile: mobileNumber,
        user_otp: otpValue,
      });
      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "OTP verified.");
        setShowSuccessModal(true); // Show success modal
        reset();
        setLoginShowotp(false);
      } else {
        toast.error(data?.MESSAGE || "OTP verification failed.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      toast.dismiss(loadingId);
    }
  };

  const onSubmit1 = (data) => {
    handleLoginSendOtp(data);
  };

  const resendOtp1 = () => {
    handleLoginSendOtp({ user_mobile: mobileNumber });
  };

  // Countdown timer for OTP resend
  useEffect(() => {
    let interval = null;
    if (isCounting) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        if (timer === 0) {
          setIsCounting(false);
          setIsOtpExpired(true);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCounting, timer]);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Loading...
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />

      {/* Center container for form/OTP section */}
      <div className="flex justify-center items-center h-[80vh] p-4">
        <div className="max-w-md w-full mx-auto p-4 border rounded-lg shadow-lg bg-white">
          {/* H2 Heading */}
          <h2 className="text-2xl font-semibold text-center mb-6">
            Delete Account
          </h2>

          {/* Conditionally show either the mobile number form or the OTP form */}
          {!loginShowotp ? (
            <form onSubmit={handleSubmit(onSubmit1)}>
              <Controller
                name="user_mobile"
                control={control}
                rules={{
                  required: "Mobile Number is required.",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Mobile Number must be 10 digits.",
                  },
                }}
                render={({ field }) => (
                  <>
                    <InputBase
                      classes={{ input: "py-0" }}
                      type="number"
                      placeholder="Enter Mobile Number"
                      className="h-10 w-full border border-[#CDCDCD] bg-[#F5F5F5] px-4 py-3 text-black mb-2"
                      inputProps={{ maxLength: 10 }}
                      {...field}
                      onInput={(e) => {
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                        field.onChange(e);
                        setMobileNumber(e.target.value);
                      }}
                    />
                    {errors.user_mobile && (
                      <p className="text-red-500 text-start mb-2">
                        {errors.user_mobile.message}
                      </p>
                    )}
                  </>
                )}
              />
              <button
                type="submit"
                className="w-full bg-[#E9B159] p-2 text-lg font-medium text-center text-white mb-4"
              >
                CONTINUE
              </button>
            </form>
          ) : (
            <div className="mb-5">
              <div className="w-full mb-6">
                <OTPInput
                  otpValue={otpValue}
                  onChange={(otp) => setOtpValue(otp)}
                  onEnter={handleSubmitOtp}
                />
              </div>
              <button
                className="w-full bg-[#E9B159] p-2 text-lg font-medium text-center text-white mb-5"
                onClick={() => handleSubmitOtp()}
              >
                SUBMIT
              </button>
              <div className="flex justify-center">
                <button
                  className="text-sm text-[#666464] underline"
                  onClick={resendOtp1}
                  disabled={timer > 0}
                >
                  {isOtpExpired ? "Resend OTP" : `Resend OTP in ${timer}s`}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4 text-center">
              Successfully
            </h3>
            <p className="mb-4 text-center">Your account will be deleted.</p>
            <button
              className="w-full bg-[#E9B159] p-2 text-lg font-medium text-center text-white"
              onClick={() => setShowSuccessModal(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteUserAccountPage;
