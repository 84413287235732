import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { STORAGE } from "../config/config";
import bannerHomepage from "../assets/images/logo-gold.png";
import { Helmet } from "react-helmet";

import { Popper } from "@mui/material";

const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));
const CategorySection = lazy(() =>
  import("../components/HomePage/CategorySection")
);
const WatchShop = lazy(() => import("../components/HomePage/WatchShop"));
const NewOnKapoorSwiper = lazy(() => import("../components/NewOnKapoorSwiper"));
const NewLaunchSwiper = lazy(() => import("../components/NewLaunchSwiper"));
const ShopByColor = lazy(() => import("../components/ShopByColor"));
const CustomerStories = lazy(() => import("../components/CustomerStories"));
const NewArrivalCard = lazy(() => import("../components/NewArrivalCard"));
const HeroSlider = lazy(() => import("../components/HeroSlider"));
const NewArrivalSection = lazy(() =>
  import("../components/HomePage/NewArrivalSection")
);
const NewOnKapoorSection = lazy(() =>
  import("../components/HomePage/NewOnKapoorSection")
);
const ImageSection = lazy(() => import("../components/HomePage/ImageSection"));
const SubscribeNewsletterSection = lazy(() =>
  import("../components/HomePage/SubscribeNewsletterSection")
);
const NewLaunchProduct = lazy(() =>
  import("../components/HomePage/NewLaunchProduct")
);
const FestivalSpecialSection = lazy(() =>
  import("../components/HomePage/FestivalSpecialSection")
);
const ShopByColorSection = lazy(() =>
  import("../components/HomePage/ShopByColorSection")
);
const CustomerStoriesSection = lazy(() =>
  import("../components/HomePage/CustomerStoriesSection")
);
const ServicesSection = lazy(() =>
  import("../components/HomePage/ServicesSection")
);
const OurStoresSection = lazy(() =>
  import("../components/HomePage/OurStoresSection")
);
const MapSection = lazy(() => import("../components/HomePage/MapSection"));
const PopularSearchSection = lazy(() =>
  import("../components/HomePage/PopularSearchSection")
);
const DescriptionSection = lazy(() =>
  import("../components/HomePage/DescriptionSection")
);

const HomePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchHomePageDetails = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      setLoading(true);
      const { data } = await axios.post("/home", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_id: userProfile?.id,
        is_mobile: "0",
        is_admin: "0",
      });

      if (data && data.STATUS === 200) {
        setData(data.DATA || []);
        // console.log("homedata", data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const divRef = useRef(null);

  useEffect(() => {
    fetchHomePageDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>
          Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online -
          Kapoorlehengasaree
        </title>
        <meta
          name="description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta
          property="og:title"
          content="Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online - Kapoorlehengasaree"
        />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta property="og:url" content="https://kapoorlehengasaree.com/" />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta
          name="twitter:title"
          content="Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online - Kapoorlehengasaree"
        />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <>
            <div className="loader-overlay">
              <div className="loader-container">
                <div className="loader-circle-9">
                  Kapoor
                  <span></span>
                </div>
              </div>
            </div>
          </>
        }
      >
        <TopBar />
        <Header />
        {/* <div > */}
          <HeroSlider
            bannerList={data?.AllBanners?.banner1}
            onClick={() => {
              navigate(
                `/product-page?category=${data?.AllBanners?.banner1.category_id}`
              );
            }}
          />
        {/* </div> */}
        {/* Category section */}
        <section className="pt-[25px] pb-[30px]">
          <CategorySection data={data?.categorydata} />
        </section>

        {/* Watch and shop section */}
        <WatchShop data={data?.watch_and_shop} />

        {/* Third section */}
        <div className="my-[30px]">
          <div className="max-w-[1804px] mx-auto lg:px-3 overflow-hidden">
            <Link
              className="block h-[100px] lg:h-auto"
              to={`/product-page?category=${data?.AllBanners?.banner3?.[0]?.category_id}`}
            >
              <img
                src={data?.AllBanners?.banner3?.[0]?.file}
                className="w-full h-full object-cover  transition-transform duration-1000 ease-in-out transform hover:scale-105"
                alt=""
                loading="lazy"
              />
            </Link>
          </div>
        </div>

        {/* New arrival section */}

        <NewArrivalSection data={data} />

        {/* New on kapoor Section */}

        <NewOnKapoorSection data={data} />

        <ImageSection data={data} />

        <SubscribeNewsletterSection />

        <NewLaunchProduct data={data} />

        {/* Festival Special Section */}

        <FestivalSpecialSection data={data} />

        {/* section */}
        <div className="my-[30px]">
          <div className="max-w-[1804px] mx-auto px-3 overflow-hidden">
            <Link
              className="block h-[200px]"
              to={`/product-page?category=${data?.AllBanners?.banner4?.[0]?.category_id}`}
            >
              <img
                src={data?.AllBanners?.banner4?.[0]?.file}
                className="w-full h-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                alt=""
                loading="lazy"
              />
            </Link>
          </div>
        </div>

        {/* shop by color section */}
        {/* <section className="mt-[30px]">
          <div className="max-w-[1836px] mx-auto">
            <div className="text-center mb-[35px]">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-normal mb-3">
                Shop by Color
              </h2>
              <p className="xl:text-sm 2xl:text-lg !leading-none font-normal italic">
                &quot;Explore a vibrant world of style by color&quot;
              </p>
            </div>
            <div className="w-[100%] h-[30%]">
              <ShopByColor info={data?.colorist_product} />
            </div>
          </div>
        </section> */}
        <ShopByColorSection data={data} />

        {/* CustomerStories section */}

        <CustomerStoriesSection data={data} />

        {/* section */}

        <ServicesSection />

        {/* OUR STORES section */}

        <OurStoresSection data={data} />

        {/* section */}

        <MapSection />

        <PopularSearchSection data={data} />

        <DescriptionSection data={data} />
        <Footer />
      </Suspense>
    </>
  );
};

export default HomePage;
