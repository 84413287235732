import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import SubscribeNewLetterPage from "./pages/SubscribeNewLetterPage";
import BlogPage from "./pages/BlogPage";
import VideoAppointmentPage from "./pages/VideoAppointmentPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutCompanyPage from "./pages/AboutCompanyPage";
import TeamDetailsPage from "./pages/TeamDetailsPage";
import SuccessStoriesPage from "./pages/SuccessStoriesPage";
import CertificatePage from "./pages/CertificatePage";
import FranchisePage from "./pages/FranchisePage";
import AccountPage from "./pages/AccountPage";
import AffiliateProgramPage from "./pages/AffiliateProgramPage";
import MyEarnPage from "./pages/MyEarnPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import OrderHistoryPage from "./pages/OrderHistoryPage";
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import WishlistPage from "./pages/WishlistPage";
import ChooseAddressPage from "./pages/ChooseAddressPage";
import ProductPage from "./pages/ProductPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ScrollToTop from "./helper/ScrollToTop";
import OurStorePage from "./pages/OurStorePage";
import { Toaster } from "react-hot-toast";
import { CartProvider } from "./context/CartContext";
import { WishlistProvider } from "./context/WishlistContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoginDrawerProvider } from "./context/LoginDrawerContext";
import TermAndConditionPage from "./pages/TermAndConditionPage";
import RefundsAndCancellations from "./pages/refundandcancallation";
import PrivancyPolicy from "./pages/Privancy police";
import MyOrderpage from "./pages/MyOrderpage";
import { ReturnProduct } from "./pages/ReturnProduct";
import { STORAGE } from "./config/config";
import { UserProvider } from "./context/UserContext ";
import { useEffect, useState } from "react";
import { SiLivechat } from "react-icons/si";
import TawkTo from "./components/TawkTo";
import { IoLogoWhatsapp } from "react-icons/io5";
import FaqsPage from "./pages/FaqsPage";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { routes } from "./routes/routes";
import NotFoundPage from "./pages/NotFoundPage";
import { FaVideo } from "react-icons/fa";

function App() {
  const deviceId = localStorage.getItem(STORAGE?.DEVICEID);
  if (!deviceId) {
    const newDeviceId = Date.now();
    localStorage.setItem(STORAGE?.DEVICEID, newDeviceId);
  }

  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Router>
        {/* <TopBar /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <LoginDrawerProvider>
            <CartProvider>
              <WishlistProvider>
                <UserProvider>
                  <ScrollToTop />
                  {/* <Header /> */}

                  <Toaster
                    position="top-right"
                    reverseOrder={false}
                    toastOptions={{
                      duration: 2000,
                    }}
                  />
                  <Routes>
                    {routes.map((route) => (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>

                  {/* <Footer /> */}
                </UserProvider>
              </WishlistProvider>
            </CartProvider>
          </LoginDrawerProvider>
        </LocalizationProvider>
      </Router>
      {showButtons && (
        <div className="scroll-up-top">
          <div>
            <button className="left-button-scroll" onClick={scrollToTop}>
              <MdOutlineKeyboardArrowUp size={32} />
            </button>
          </div>
        </div>
      )}
      {showButtons && (
        <div className="sticky-buttons">
          <div className="button-group">
            <button className="left-button">
              <a
                href="https://wa.me/+917409555455?text=Hello How can I help you ?"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center "
              >
                <IoLogoWhatsapp size={32} />
              </a>
            </button>
            <button>
              <TawkTo />
            </button>
          </div>
        </div>
      )}
      {/* {showButtons && ( */}
      <div className="sticky-buttons">
        <button className="video-call-button video-button">
          <a
            href="/video-appointment"
            rel="noopener noreferrer"
            className="flex"
          >
            <div className="btn btn-primary">
              <FaVideo size={20} />
            </div>
          </a>
        </button>
      </div>
      {/* )} */}
    </>
  );
}

export default App;
