import React from "react";
import { FaClock } from "react-icons/fa";

const WithdrawHistoryCard = ({ data }) => {
  return (
    <div>
      <div className="flex items-center justify-between px-3 md:px-8 py-4 bg-[#F5F5F5] rounded-md md:rounded-xl lg:rounded-[17px]">
        <div className="flex items-center gap-4 md:gap-[34px]">
          <span className="flex-shrink-0 hidden sm:inline-block ">
            <FaClock className="w-[100%] text-[#f30404]" />
          </span>
          <div>
            <h3 className="sm:text-xl md:text-2xl font-medium !leading-none font-jost mb-2.5 md:mb-3.5">
              ID:{data?.id}
            </h3>
            <p className="text-sm sm:text-lg md:text-xl  font-medium font-jost !leading-[1.4] text-[#7c7c7c]">
              {data?.created_at}
            </p>
          </div>
        </div>
        <span className="sm:text-xl md:text-2xl font-medium !leading-none font-Roboto">
          {data?.given_amount}
        </span>
      </div>
    </div>
  );
};

export default WithdrawHistoryCard;
