// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnTf_xSIF06sGmy6oUnmW9jOn1LJlClVg",
  authDomain: "kapoorlogin.firebaseapp.com",
  projectId: "kapoorlogin",
  storageBucket: "kapoorlogin.appspot.com",
  messagingSenderId: "567604383366",
  appId: "1:567604383366:web:716acef28ed42c0a6ccb07"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth();
export const db=getFirestore(app);
export default app;