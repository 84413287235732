import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));
const Breadcrumb = lazy(() => import("../components/Breadcrumb"));
const TeamCard = lazy(() => import("../components/TeamCard"));

const AboutCompanyPage = () => {
  const [companyInfo, setCompanyInfo] = useState();
  const [loading, setLoading] = useState(true);

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to="/about-us"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      About Us
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      About Company
    </p>,
  ];

  const fetchCompanyInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("about_company");
      if (data && data?.STATUS === 200) {
        setCompanyInfo(data?.DATA?.[0]);
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.MESSAGE ||
          err?.message ||
          "Failed to fetch information."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <>
            <div className="loader-overlay">
              <div className="loader-container">
                <div className="loader-circle-9">
                  Kapoor
                  <span></span>
                </div>
              </div>
            </div>
          </>
        }
      >
        <TopBar />
        <Header />
        <Breadcrumb list={breadcrumbArray} />
        <div className="max-w-[1608px] mx-auto px-3 mt-6 mb-10 md:mb-20 xl:mb-[100px]">
          <div className="mb-4 md:mb-6">
            <img
              src="/images/logo-gold.png"
              className="w-[200px] mx-auto"
              alt=""
              loading="lazy"
            />
          </div>
          <p className="sm:text-xl font-normal !leading-[1.23] text-black font-jost mb-6">
            {companyInfo?.long_description}
          </p>
          <h4 className="text-2xl lg:text-3xl 2xl:text-4xl font-medium !leading-[1.23] text-black font-jost mb-3 text-center">
            Vision
          </h4>
          <p className="sm:text-xl font-normal !leading-[1.23] text-black font-jost mb-6">
            {companyInfo?.vision}
          </p>
          <h4 className="text-2xl lg:text-3xl 2xl:text-4xl font-medium !leading-[1.23] text-black font-jost mb-3 text-center">
            Mission
          </h4>
          <p className="sm:text-xl font-normal !leading-[1.23] text-black font-jost mb-6">
            {companyInfo?.mission}
          </p>
          <div className="bg-[#F4F4F4] py-10 px-5 md:px-10 2xl:px-24">
            <h4 className="text-2xl lg:text-3xl 2xl:text-4xl font-medium !leading-[1.23] text-black font-jost mb-3 md:mb-6 text-center">
              Founders
            </h4>
            <p className="sm:text-xl font-normal !leading-[1.23] text-black font-jost mb-6">
              To be a preferred company of choice in Indian fashion globally for
              its delightful customer service, and quality product offerings by
              constantly evolving using innovation and design.
            </p>

            {companyInfo?.details?.map((item, index) => {
              return (
                <TeamCard
                  customClass={
                    "mb-5 md:mb-8 xl:mb-11 flex-row even:flex-row-reverse"
                  }
                  key={"founder" + index}
                  info={item}
                />
              );
            })}
          </div>
        </div>
        <Footer />
      </Suspense>
    </>
  );
};

export default AboutCompanyPage;
