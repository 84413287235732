import React from "react";
import { FaBoxOpen } from "react-icons/fa";

const OrderCard = ({ returnInfo }) => {
  return (
    <div className="border border-[#EBEBEB] bg-white p-6">
      {returnInfo && returnInfo.length > 0 ? (
        returnInfo.map((repro, index) => {
          return (
            <div className="py-[15px] px-3 sm:px-4 md:px-[30px]" key={index}>
              <h5 className="text-lg md:text-xl xl:text-[30px] !leading-[167%] font-medium mb-4">
                Delivered {repro?.delivered_date}
              </h5>
              <div className="flex flex-wrap gap-4 md:gap-2 justify-between items-center">
                <div className="flex gap-3 md:gap-5 xl:gap-[30px] md:flex-1 w-full">
                  <div className="flex-shrink-0 w-24 h-28 md:w-[155px] md:h-[195px]">
                    <img
                      src={repro?.product_image}
                      className="w-full h-full object-top object-cover"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div className="max-w-[620px] w-full">
                    <h3 className="text-lg xs:text-xl md:text-2xl xl:text-3xl !leading-[134%] font-normal text-black mb-3 md:mb-4">
                      {repro?.product_name}
                    </h3>
                    <p className="md:text-2xl xl:text-3xl leading-9 font-bold text-black mb-4">
                      ₹{repro?.returned_amount}
                      <span className="text-sm md:text-[15px] !leading-[120%] text-[#828181] font-medium line-through">
                        {" "}
                        MRP:₹{repro?.product_mrp} ||{" "}
                      </span>{" "}
                      <span className="block md:inline-block text-sm md:text-[15px] !leading-[120%] text-[#F30404] font-medium">
                        {" "}
                        [{repro?.product_discount}% OFF]
                      </span>
                    </p>
                    <div className="flex flex-wrap items-center gap-3 md:gap-4">
                      <p className="text-sm xs:text-base md:text-lg xl:text-xl !leading-[65%] text-[#312F2F]">
                        Size: Free Size
                      </p>
                      <p className="text-sm xs:text-base md:text-lg xl:text-xl !leading-[65%] text-[#312F2F]">
                        Color: Purple
                      </p>
                      <p className="text-sm xs:text-base md:text-lg xl:text-xl !leading-[65%] text-[#312F2F]">
                        Qty: {repro?.product_quantity}
                      </p>
                    </div>
                    <div>
                      <p className="text-base text-black bg-green-500 mt-3 p-2 inline-block">
                        {repro?.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center text-xl font-bold text-slate-500 bg-[#eeeeee] p-40 flex justify-center items-center gap-2">
          <FaBoxOpen size="30px" className="text-dark-400" />
          No return products available.
        </div>
      )}
    </div>
  );
};

export default OrderCard;
