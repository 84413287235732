import React, { useEffect, useState } from "react";
import { InputBase } from "@mui/material";
import WithdrawHistoryCard from "../components/WithdrawHistoryCard";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { STORAGE } from "../config/config";
import axios from "axios";
import { useUser } from "../context/UserContext ";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const MyEarnPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to="/affiliate-program"
      underline="hover"
      key="2"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Affiliate Program
    </Link>,
    <p
      key="3"
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      My Earn
    </p>,
  ];

  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleWithdrawOpen = () => setOpenWithdraw(true);
  const handleWithdrawClose = () => setOpenWithdraw(false);
  const handleSuccessModalOpen = () => setOpenSuccessModal(true);
  const handleSuccessModalClose = () => {
    setOpenSuccessModal(false);
    setWithdrawAmount("");
  };

  const { userDetails } = useUser();
  const [linkInfo, setLinkInfo] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [message, setMessage] = useState("");
  const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLinkInfo = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      setLoading(true);
      const { data } = await axios.post("/affiliatedetail", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_id: userProfile?.id,
      });

      if (data && data.STATUS === 200) {
        setLinkInfo(data.DATA || []);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchWithdrawHistory = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("/userwithdrawhistory", {
        user_id: userProfile?.id,
      });

      if (data && data.STATUS === 200) {
        setWithdrawHistory(data.DATA || []);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleWithdraw = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    const amount = parseFloat(withdrawAmount);

    if (isNaN(amount) || amount <= 0) {
      setMessage("Please enter a positive amount.");
      return;
    }

    if (amount > linkInfo?.available_balance) {
      setMessage("Withdraw amount exceeds available balance");
      return;
    }

    try {
      const { data } = await axios.post("/affiliateWithdraw", {
        wallet_amount: amount,
        user_id: userProfile?.id,
      });

      if (data && data.STATUS === 200) {
        handleSuccessModalOpen();
        fetchWithdrawHistory();
      } else {
        setMessage("Withdrawal failed");
      }
    } catch (err) {
      setMessage("Error during withdrawal");
    }
  };

  useEffect(() => {
    fetchLinkInfo();
    fetchWithdrawHistory();
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div
        className="bg-repeat-x bg-[top_center] w-full py-8 md:py-14"
        style={{ backgroundImage: "url(/images/affiliate-program-bg.png)" }}
      >
        <div className="max-w-[1418px] mx-auto px-3">
          <div className="bg-white px-4 sm:px-5 md:px-8 xl:px-[70px] py-[30px] lg:pb-10 border-2 border-[#ECEAEA]">
            <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-5">
              Your Earn
            </h2>
            <div className="max-w-[994px] mx-auto">
              {/* User Details Section */}
              <div className="text-center mb-5 md:mb-10 xl:mb-[60px]">
                <div className="h-28 w-28 md:h-[200px] md:w-[200px] mx-auto border-[3px] md:border-[6px] border-[#e2e2e2] rounded-full overflow-hidden mb-6 md:mb-10 2xl:mb-14">
                  <img
                    src={
                      userDetails.user_profile || "/images/account-avatar.png"
                    }
                    className="h-full w-full object-cover"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <h4 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-[15px] flex justify-center items-center gap-1">
                  <span>
                    {userDetails.user_first_name} {userDetails.user_last_name}
                  </span>
                  <span>
                    <RiVerifiedBadgeFill className="lg:h-9 lg:w-9 text-[#33B705]" />
                  </span>
                </h4>
                <p className="text-sm sm:text-2xl !leading-none font-medium text-[#636161] font-jost">
                  {userDetails.user_email}
                </p>
              </div>

              {/* Earnings Section */}
              <div className="bg-[#F5F5F5] border-[2.58px] border-[#D7D7D7] px-3 md:px-10 xl:ps-[67px] xl:pr-[94px] py-5 md:py-10 mb-5 md:mb-8 xl:mb-[50px]">
                <div className="flex flex-wrap items-center gap-y-4 text-center sm:text-start mb-6 xl:mb-14">
                  <div className="w-full sm:w-6/12 px-3 sm:border-r-[2.6px] border-[#d7d7d7] md:pe-[66px]">
                    <h5 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-2.5 sm:mb-[15px]">
                      This Month Earning
                    </h5>
                    <p className="text-xl sm:text-2xl !leading-[1.2] text-[#33b705] font-semibold font-jost">
                      {linkInfo?.thismonth_earning}
                    </p>
                  </div>
                  <div className="w-full sm:w-6/12 px-3 md:pl-[66px]">
                    <h5 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-2.5 sm:mb-[15px]">
                      Available Balance
                    </h5>
                    <p className="text-xl sm:text-2xl !leading-[1.2] text-[#1d1d1d] font-semibold font-jost">
                      {linkInfo?.available_balance}
                    </p>
                  </div>
                </div>
                <div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleWithdraw();
                    }}
                  >
                    <div className="flex flex-col sm:flex-row items-center !border-[2.6px] border-solid !border-[#d7d7d7] bg-white py-3 px-3">
                      <InputBase
                        type="number"
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        classes={{ input: "py-0" }}
                        placeholder="Enter Withdraw Amount"
                        className="pb-4 sm:pb-0 flex-1 w-full sm:w-auto sm:text-xl lg:text-2xl 2xl:text-[34px] font-medium !leading-none font-jost text-black !placeholder:text-[#464646]"
                      />
                      <button
                        type="submit"
                        className="w-full sm:w-auto flex-shrink-0 sm:text-lg md:text-xl font-bold !leading-none font-jost text-[#908787] bg-white py-3 px-[54px] !border-[2.6px] border-solid !border-[#d7d7d7] hover:bg-[#e9b15a] hover:text-white"
                      >
                        Submit
                      </button>
                    </div>
                    {message && <p className="text-red-500 mt-2">{message}</p>}
                  </form>
                </div>
              </div>

              {/* Withdraw History Section */}
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-6 md:mb-9 text-center">
                Withdraw History
              </h2>
              <div className="grid gap-[21px]">
                {withdrawHistory.length > 0 ? (
                  withdrawHistory.map((historyItem, index) => (
                    <WithdrawHistoryCard key={index} data={historyItem} />
                  ))
                ) : (
                  <div className="bg-[#eeeeee] h-[280px] flex justify-center items-center">
                    <div className="w-full text-center text-lg lg:text-xl xl:text-2xl text-[#555555]">
                      No withdrawal history available.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Withdraw Success Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSuccessModal}
        onClose={handleSuccessModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center p-4 md:p-0">
          <div className="max-w-[500px] w-full bg-white py-6 sm:py-12 px-5 sm:px-10 lg:px-[106px] text-center">
            <h3 className="text-2xl xl:text-[32px] font-semibold !leading-[1.22] text-black mb-3 lg:mb-5">
              Withdrawal Successful
            </h3>
            <p className="text-lg md:text-xl !leading-[1.3] font-medium font-jost text-[#545454] max-w-[426px] mx-auto mb-6 md:mb-10 xl:mb-[75px]">
              Your withdrawal has been processed successfully.
            </p>
            <button
              onClick={handleSuccessModalClose}
              className="bg-[#E9B159] p-3 xl:p-7 w-full text-lg lg:text-2xl font-medium leading-10 text-center text-white"
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default MyEarnPage;
