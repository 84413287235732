import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { InputBase } from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";
import toast from "react-hot-toast";
import axios from "axios";
import { STORAGE } from "../config/config";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LoginDrawerContext } from "../context/LoginDrawerContext";

const VideoAppointmentPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to="/blog"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Blog/Article
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Video Appointment
    </p>,
  ];

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    mobile_number: yup
      .string()
      .required("Contact number is required")
      .length(10, "Invalid Contact Number."),
    date: yup.string().required("Date is required"),
    time: yup.string().required("Time is required"),
    budget: yup.string().required("Shopping budget is required"),
    requirement: yup.string().required("Requirement is required"),
    customer_pickup: yup.string().required("Pickup point is required"),
  });

  const {
    control,
    getFieldState,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      mobile_number: "",
      date: null,
      time: null,
      budget: "",
      requirement: "",
      customer_pickup: "",
    },
    resolver: yupResolver(schema),
  });

  function getFormatedTime(dateString) {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours() % 12 || 12;
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    const ampm = dateObj.getHours() >= 12 ? "PM" : "AM";
    const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds} ${ampm}`;

    return formattedTime;
  }

  const { setShowLogin } = useContext(LoginDrawerContext);

  const isLoggedIn = localStorage.getItem(STORAGE?.ISLOGIN);

  const handleTakeVideoCall = async (payload) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    console.log("videocall", {
      device_id: localStorage.getItem(STORAGE?.DEVICEID),
      is_admin: "0",
      is_mobile: "0",
      user_id: userProfile?.id,
      ...payload,
    });
    try {
      const { data } = await axios.post("videocall_schedual", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        is_admin: "0",
        is_mobile: "0",
        user_id: userProfile?.id,
        ...payload,
      });

      if (isLoggedIn) {
        if (data && data?.STATUS === 200) {
          toast.success(data?.MESSAGE || "Video call scheduled.");
          reset();
        } else {
          toast.error(data?.MESSAGE || "Scheduling video call failed.");
        }
      } else {
        setShowLogin(true);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE || err?.message || "Registration failed."
      );
    }
  };

  const onSubmit = (data) => {
    const newData = {
      ...data,
      date: dayjs(data.date).format("DD-MM-YYYY"),
      time: getFormatedTime(data.time),
    };
    handleTakeVideoCall(newData);
  };

  return (
    <>
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div
        className="bg-repeat-x bg-[top_center] w-full pt-8 md:pt-14"
        style={{ backgroundImage: "url(/images/video-appointment-bg.png)" }}
      >
        <div className="max-w-[1418px] mx-auto px-3">
          <div className="w-full bg-white pt-10 pb-[68px] px-3">
            <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-10 xl:mb-[74px] text-center">
              Book your appointment for Live Video shopping
            </h2>
            <div className="flex flex-wrap -mx-3 gap-y-3">
              <div className="w-full sm:w-4/12 px-3 text-center">
                <div className="mb-3 md:mb-6">
                  <img
                    src="/images/calendar.png"
                    className="mx-auto h-10 md:h-auto"
                    alt=""
                  />
                </div>
                <p className="sm:text-sm lg:text-lg  !leading-[1.31] max-w-[192px] mx-auto">
                  Book your appointment for Video shopping
                </p>
              </div>
              <div className="w-full sm:w-4/12 px-3 text-center">
                <div className="mb-3 md:mb-6">
                  <img
                    src="/images/shopping-cart.png"
                    className="mx-auto h-10 md:h-auto"
                    alt=""
                  />
                </div>
                <p className="sm:text-sm lg:text-lg !leading-[1.31] max-w-[335px] mx-auto">
                  Our expert will connect soon. Get ready to enjoy the best
                  personalised shopping experience.
                </p>
              </div>
              <div className="w-full sm:w-4/12 px-3 text-center">
                <div className="mb-3 md:mb-6">
                  <img
                    src="/images/shipped.png"
                    className="mx-auto h-10 md:h-auto"
                    alt=""
                  />
                </div>
                <p className="sm:text-sm lg:text-lg !leading-[1.31] max-w-[259px] mx-auto">
                  Now you may relax and wait for us to deliver your package of
                  happiness.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#FEFAF3] px-5 md:px-8 xl:pl-[60px] xl:pr-[75px] pt-6 pb-10">
            <div className="flex flex-wrap -mx-3 gap-y-4">
              <div className="w-full lg:w-6/12 px-3">
                <h3 className="text-center sm:text-sm lg:text-lg  font-medium !leading-none mb-3.5">
                  Schedule a video call
                </h3>
                <p className="text-center max-w-[401px] mx-auto sm:text-sm lg:text-lg !leading-[1.27] text-[#4D4D4D] mb-4 md:mb-6 xl:mb-9">
                  Get connected by scheduling a call with one of our agents
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid gap-y-5 lg:gap-y-[30px]">
                    <div className="w-full">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <InputBase
                                classes={{ input: "py-0" }}
                                placeholder="Full Name"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black !placeholder:text-[#464646] "
                                {...field}
                                error={!!errorMessage}
                              />
                              <p className="text-red-500">{errorMessage}</p>
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <Controller
                        name="mobile_number"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <InputBase
                                classes={{ input: "py-0" }}
                                type="number" // Change type to text
                                placeholder="Contact Number"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black !placeholder:text-[#464646] "
                                inputProps={{ maxLength: 10 }}
                                {...field}
                                // required
                              />

                              <p className="text-red-500">{errorMessage}</p>
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full custom-field">
                      <Controller
                        name="date"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <DatePicker
                                placeholder="DD/MM/YYYY"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black !placeholder:text-[#464646] "
                                classes={{ input: "py-0" }}
                                {...field}
                                error={!!errorMessage}
                                minDate={dayjs()}
                              />

                              <p className="text-red-500">{errorMessage}</p>
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full custom-field">
                      <Controller
                        name="time"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <TimePicker
                                placeholder="Enter Time"
                                classes={{ input: "py-0" }}
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black !placeholder:text-[#464646] "
                                {...field}
                                minTime={dayjs().hour(10).minute(0).second(0)}
                                maxTime={dayjs().hour(21).minute(30).second(0)}
                                closeOnSelect={false}
                              />

                              <p className="text-red-500">{errorMessage}</p>
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <Controller
                        name="budget"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <InputBase
                                classes={{ input: "py-0" }}
                                placeholder="Shopping Budget"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black"
                                {...field}
                              />
                              {errorMessage && (
                                <p className="mt-0.5 ml-2 text-xs text-red-600">
                                  {errorMessage}
                                </p>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <Controller
                        name="requirement"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <InputBase
                                classes={{ input: "py-0" }}
                                placeholder="Enter Requirement"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black"
                                {...field}
                              />
                              {errorMessage && (
                                <p className="mt-0.5 ml-2 text-xs text-red-600">
                                  {errorMessage}
                                </p>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <Controller
                        name="customer_pickup"
                        control={control}
                        render={({ field }) => {
                          const errorMessage = getFieldState(field.name)?.error
                            ?.message;
                          return (
                            <>
                              <InputBase
                                classes={{ input: "py-0" }}
                                placeholder="Pickup Point"
                                className="h-12 w-full !text-sm sm:!text-md !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black"
                                {...field}
                              />
                              {errorMessage && (
                                <p className="mt-0.5 ml-2 text-xs text-red-600">
                                  {errorMessage}
                                </p>
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <p className="sm:text-sm lg:text-lg font-medium !leading-[1.31]">
                        Note: Video Call Time is 10:00 AM to 09:30 PM.
                      </p>
                    </div>
                    <div className="w-full">
                      <button
                        variant="contained"
                        type="submit"
                        className="w-full h-12 text-center bg-[#E9B159] hover:bg-[#E9B159] text-white text-xl xl:text-2xl font-jost rounded-md sm:rounded-[10px]"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="hidden w-full lg:w-6/12 px-3 text-end lg:flex">
                <img
                  src="/images/appointment-element.png"
                  className="ml-auto my-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="my-10 md:mt-14 lg:mb-[70px]">
            <img
              src="/images/video-appointment-adv.png"
              className="w-full"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VideoAppointmentPage;
