import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import GoogleReviewCard from "./GoogleReviewCard";

const GoogleReviewSwiper = ({ info }) => {
  return (
    <>
      <div className="relative">
        <Swiper
          className="relative"
          spaceBetween={16}
          slidesPerView={1}
          loop
          breakpoints={{
            550: {
              slidesPerView: 1.2,
            },
            750: {
              slidesPerView: 2.2,
            },
            1200: {
              slidesPerView: 2.7,
              spaceBetween: 30,
            },
          }}
          autoplay={{
            delay: 5000,
          }}
        >
          {info?.Google_Reviews?.map((item, index) => {
            return (
              <SwiperSlide key={"Google_Reviews" + index} className="!h-auto">
                <GoogleReviewCard info={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default GoogleReviewSwiper;
