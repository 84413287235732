import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import ProductImageSlider from "./ProductImageSlider";
import { FaHeart } from "react-icons/fa";
import { CartContext } from "../context/CartContext";
import { STORAGE } from "../config/config";
import axios from "axios";
import toast from "react-hot-toast";
const WishlistProductCard = ({ data, removeFromWishlist }) => {
  const { fetchCartItems } = useContext(CartContext);

  const navigate = useNavigate();

  const handleAddToCartClick = async (id) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("addtocart", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        is_mobile: "0",
        product_id: id,
        product_quantity: 1,
        stching: "unstitched",
        is_admin: "0",
        user_id: userProfile?.id,
      });
      if (data && data?.STATUS === 200) {
        fetchCartItems();
        // console.log("data::", data.DATA);
        // console.log("click");
        toast.success(data?.MESSAGE || "Product added to cart.");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Failed to add to cart.");
    }
  };

  const truncateProductName = (name) => {
    if (name.length > 18) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  return (
    <Link
      // to={`/product/${data?.id}/${data?.product_name}`}
      // onClick={()=>{
      //   navigate(`/product/${data?.product_name}}`,
      //     {
      //       state:{
      //         id:data?.id,
      //         name:data?.product_name
      //       }
      //     }
      //   )
      // }}
      to={`/product/${data?.product_name}`}
    >
      <div className="group transition hover:shadow-[0px_0px_30px_0px_#0000004D]">
        <div className="aspect-[2/3] relative">
          <div className="h-full">
            <ProductImageSlider imageList={data?.product_image} />
          </div>
          <div className="absolute z-10 left-0 bottom-2.5 w-full px-4 opacity-0 transition group-hover:opacity-100">
            <button
              className="w-full border-none text-sm md:text-base 2xl:text-lg font-normal px-4 py-2 md:py-3 bg-white text-black block text-center uppercase border border-[#D3D3D3] rounded"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleAddToCartClick(data?.id || data?.product_id);
                removeFromWishlist(data?.id);
              }}
            >
              Move To Bag
            </button>
          </div>
          <div className="absolute right-2 top-5 z-10 opacity-1 transition h-9 w-9 bg-white grid place-content-center rounded-full">
            <button
              className="inline-flex"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                removeFromWishlist(data?.id);
              }}
            >
              <FaHeart className="text-[#FF3F3F] text-lg" />
            </button>
          </div>
        </div>
        <div className="p-2.5 sm:p-4 sm:pb-5">
          <h3 className="text-sm sm:text-base font-normal !leading-[18px] mb-0.5 capitalize">
            {truncateProductName(data?.product_name || "")}
          </h3>
          <div className="flex items-center gap-2">
            <span className="text-[13px] font-semibold leading-[17px] text-[#505050] line-through">
              ₹{data?.product_mrp}
            </span>
            <span className="text-sm sm:text-base font-semibold text-[#03A685]">
              ₹{data?.product_price}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WishlistProductCard;
