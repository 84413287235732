import React, { useEffect, useState } from "react";
import kapoorImg from "../assets/images/kapoorimg.png";
import axios from "axios";
import { Circles } from "react-loader-spinner";

import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { Helmet } from "react-helmet";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivancyPolicy = () => {
  const [policy, setPolicy] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchpolicyApi = async () => {
    try {
      const response = await axios.get("Infopages");
      setPolicy(response.data.DATA);
    } catch (error) {
      console.error("There was an error fetching the policy!", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchpolicyApi();
  }, []);

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Privacy Policy
    </p>,
  ];

  const insertLineBreaks = (content) => {
    return content.replace(/\./g, ".<br/>");
  };
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Kapoorlehengasaree</title>
        <meta
          name="description"
          content="If you have any query or need general information you can fill the form below or contact us at support@kapoorlehengasaree.com , we normally respond in"
        />
        <meta property="og:title" content="Privacy Policy" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://kapoorlehengasaree.com/privacypolicy"
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="Privacy Policy" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href="https://kapoorlehengasaree.com/privancypolicy"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>

      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div className="flex justify-center items-center mt-5">
        <img src="/images/logo-gold.png" className="w-[200px]" alt=""  loading="lazy"/>
      </div>
      <div class="max-w-[1600px] mx-auto px-3">
        <div className="py-10">
          <h4 className="text-xl lg:text-2xl font-medium mb-3">Introduction</h4>
          <p>
            {policy.privacy_policy ? (
              <div
                className="text-sm xl:text-lg"
                dangerouslySetInnerHTML={{
                  __html: insertLineBreaks(policy.privacy_policy),
                }}
              />
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivancyPolicy;
