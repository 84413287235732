import React, { useState, useRef, useEffect } from "react";

const OTPInput = ({ otpValue, onChange, disabled ,onEnter}) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    // Automatically focus on the first input field when the component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    otp[index] = value;
    setOtp([...otp]);
    onChange(otp.join(""));

    // Focus the next input box
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" || e.key === 'Enter') {
      if (!otp[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }
      onEnter();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    const otpArray = paste.slice(0, 6).split("");
    setOtp(otpArray);
    onChange(otpArray.join(""));
    otpArray.forEach((value, index) => {
      inputRefs.current[index].value = value;
    });
  };

  const handleReset = () => {
    setOtp(new Array(6).fill(""));
    onChange("");
    inputRefs.current[0].focus();
  };

  return (
    <div className="otp-input-container" onPaste={handlePaste}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={data}
          ref={(ref) => inputRefs.current[index] = ref}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="otp-input-box"
          disabled={disabled}
        />
      ))}
      <button onClick={handleReset} className="clear-otp-button">
        Clear OTP
      </button>
    </div>
  );
};

export default OTPInput;