// import React, { useEffect, useState } from "react";
// import { CopyIcon, ShareIcon, WalletIcon } from "../assets/SvgIcons";
// import { Link } from "react-router-dom";
// import { STORAGE } from "../config/config";
// import axios from "axios";
// import toast from "react-hot-toast";

// const AffiliateProgramLink = () => {
//   const [linkInfo, setLinkInfo] = useState(null);

//   const fetchLinkInfo = async () => {
//     const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
//     try {
//       const { data } = await axios.post("/affiliatedetail", {
//         device_id: localStorage.getItem(STORAGE?.DEVICEID),
//         user_id: userProfile?.id,
//       });

//       if (data && data.STATUS === 200) {
//         setLinkInfo(data.DATA || []);
//         console.log("linkdata", data);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const handleCopy = (code) => {
//     navigator.clipboard
//       .writeText(code)
//       .then(() => {
//         toast.success("Code copied to clipboard!");
//       })
//       .catch((err) => {
//         toast.error("Failed to copy code.");
//       });
//   };

//   const handleShare = (code) => {
//     handleCopy(code);
//     const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
//       code
//     )}`;
//     window.open(whatsappUrl, "_blank");
//   };

//   useEffect(() => {
//     fetchLinkInfo();
//   }, []);

//   return (
//     <>
//       <div className="max-w-[1418px] mx-auto px-3">
//         <div className="bg-white px-5 md:px-8 xl:pl-[60px] xl:pr-[75px] py-5 md:py-[30px] border-2 border-[#ECEAEA]">
//           <div className="w-full bg-white">
//             <div className="flex flex-wrap justify-between items-center gap-y-3">
//               <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost">
//                 Affiliate Program
//               </h2>
//               <Link
//                 to="/my-earn"
//                 className="py-1 md:py-2 px-4 md:px-[10px] inline-flex items-center gap-1.5 md:gap-2.5 border border-[#cac9c9] bg-[#f3f2f2]"
//               >
//                 <span>
//                   <WalletIcon />
//                 </span>{" "}
//                 <span className="text-lg font-medium !leading-normal text-black">
//                   My Earn
//                 </span>
//               </Link>
//             </div>
//             <div className="">
//               <img
//                 src="/images/affiliate-program-link.png"
//                 className="mx-auto"
//                 alt=""
//                 loading="lazy"
//               />
//             </div>
//             <div className="w-full bg-[#F6F6F6] py-6 md:py-10 lg:pt-[52px] lg:pb-11 px-5 md:px-[30px] xl:px-12 mb-6 md:mb-11">
//               <ul className="list-disc ps-5">
//                 <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost mb-4 md:mb-[30px]">
//                   Affiliates receive a unique tracking link or ID for
//                   performance monitoring.
//                 </li>
//                 <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost mb-4 md:mb-[30px]">
//                   Affiliates can use various channels like websites or social
//                   media to promote the product or service.
//                 </li>
//                 <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost">
//                   Affiliates earn a commission for each sale or action generated
//                   through their unique link or ID.
//                 </li>
//               </ul>
//             </div>
//             <div className="max-w-[807px] mx-auto">
//               <h4 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl font-normal !leading-[1.2] font-jost mb-[15px]">
//                 Your Links
//               </h4>
//               <div className="relative bg-neutral-100 mt-[15px] px-4 xl:pl-[35px] xl:pr-[31px] py-5 md:py-8 rounded-lg xl:rounded-[18px] border-[3.5px] border-dashed border-[#7a7a7a]">
//                 <div>
//                   <p className="break-words text-sm xl:text-2xl !leading-[1.29] font-jost text-[#0083FC]">
//                     {linkInfo?.url}
//                   </p>
//                 </div>
//                 <input
//                   type="text"
//                   readOnly
//                   className="border-none outline-none absolute w-[0.1px] opacity-5"
//                   id="my-textfield"
//                   value="http/www.cbazaar.com/product/pink-faux-gejke-thread-embroidered-pakistani-suit-p"
//                 />
//               </div>
//             </div>
//             <div className="w-full flex justify-center mt-5">
//               <div
//                 className="w-6/12 px-3 cursor-pointer"
//                 onClick={() => handleCopy(linkInfo?.url)}
//               >
//                 <div
//                   aria-label="copy text"
//                   className="w-full flex items-center justify-center gap-2.5 !bg-[#EBEBEB] text-black text-xl font-jost uppercase font-bold font-medium font-jost !border-[1.5px] border-[#D1D1D1] rounded-none py-3 px-4"
//                 >
//                   <CopyIcon /> <span>copy</span>
//                 </div>
//               </div>
//               <div
//                 className="w-6/12 px-3 cursor-pointer"
//                 onClick={() => handleShare(linkInfo?.url)}
//               >
//                 <div
//                   aria-label="share text"
//                   className="w-full flex items-center justify-center gap-2.5 bg-black text-white text-xl font-jost uppercase font-bold font-medium font-jost border border-[#D1D1D1] py-3 px-4"
//                 >
//                   <ShareIcon /> <span>Share</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AffiliateProgramLink;

import React, { useEffect, useState } from "react";
import { CopyIcon, ShareIcon, WalletIcon } from "../assets/SvgIcons";
import { Link } from "react-router-dom";
import { STORAGE } from "../config/config";
import axios from "axios";
import toast from "react-hot-toast";

const AffiliateProgramLink = () => {
  const [linkInfo, setLinkInfo] = useState(null);

  const fetchLinkInfo = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("/affiliatedetail", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_id: userProfile?.id,
      });

      if (data && data.STATUS === 200) {
        setLinkInfo(data.DATA || []);
        console.log("linkdata", data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success("Code copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy code.");
      });
  };

  const handleShare = (code) => {
    handleCopy(code);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      code
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    fetchLinkInfo();
  }, []);

  return (
    <div className="max-w-[1418px] mx-auto px-3">
      <div className="bg-white px-5 md:px-8 xl:pl-[60px] xl:pr-[75px] py-5 md:py-[30px] border-2 border-[#ECEAEA]">
        <div className="w-full bg-white">
          <div className="flex flex-wrap justify-between items-center gap-y-3">
            <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost">
              Affiliate Program
            </h2>
            <Link
              to="/my-earn"
              className="py-1 md:py-2 px-4 md:px-[10px] inline-flex items-center gap-1.5 md:gap-2.5 border border-[#cac9c9] bg-[#f3f2f2]"
            >
              <span>
                <WalletIcon />
              </span>{" "}
              <span className="text-lg font-medium !leading-normal text-black">
                My Earn
              </span>
            </Link>
          </div>
          <div className="">
            <img
              src="/images/affiliate-program-link.png"
              className="mx-auto"
              alt=""
              loading="lazy"
            />
          </div>
          <div className="w-full bg-[#F6F6F6] py-6 md:py-10 lg:pt-[52px] lg:pb-11 px-5 md:px-[30px] xl:px-12 mb-6 md:mb-11">
            <ul className="list-disc ps-5">
              <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost mb-4 md:mb-[30px]">
                Affiliates receive a unique tracking link or ID for performance monitoring.
              </li>
              <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost mb-4 md:mb-[30px]">
                Affiliates can use various channels like websites or social media to promote the product or service.
              </li>
              <li className="text-sm xl:text-2xl font-normal !leading-[1.56] font-jost">
                Affiliates earn a commission for each sale or action generated through their unique link or ID.
              </li>
            </ul>
          </div>
          <div className="max-w-[807px] mx-auto">
            <h4 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl font-normal !leading-[1.2] font-jost mb-[15px]">
              Your Links
            </h4>
            <div className="relative bg-neutral-100 mt-[15px] px-4 xl:pl-[35px] xl:pr-[31px] py-5 md:py-8 rounded-lg xl:rounded-[18px] border-[3.5px] border-dashed border-[#7a7a7a]">
              {linkInfo?.url ? (
                <p className="break-words text-sm xl:text-2xl !leading-[1.29] font-jost text-[#0083FC]">
                  {linkInfo?.url}
                </p>
              ) : (
                <p className="text-sm xl:text-2xl !leading-[1.29] font-jost text-red-500">
                  Your account has not been approved yet. Please try again later.
                </p>
              )}
              <input
                type="text"
                readOnly
                className="border-none outline-none absolute w-[0.1px] opacity-5"
                id="my-textfield"
                value={linkInfo?.url || ""}
              />
            </div>
          </div>
          <div className="w-full flex justify-center mt-5">
            <div
              className={`w-6/12 px-3 cursor-pointer ${!linkInfo?.url ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => linkInfo?.url && handleCopy(linkInfo?.url)}
            >
              <div
                aria-label="copy text"
                className={`w-full flex items-center justify-center gap-2.5 ${!linkInfo?.url ? 'bg-gray-300 text-gray-600' : 'bg-[#EBEBEB] text-black'} text-xl font-jost uppercase font-bold font-medium font-jost !border-[1.5px] border-[#D1D1D1] rounded-none py-3 px-4`}
              >
                <CopyIcon /> <span>copy</span>
              </div>
            </div>
            <div
              className={`w-6/12 px-3 cursor-pointer ${!linkInfo?.url ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => linkInfo?.url && handleShare(linkInfo?.url)}
            >
              <div
                aria-label="share text"
                className={`w-full flex items-center justify-center gap-2.5 ${!linkInfo?.url ? 'bg-gray-300 text-gray-600' : 'bg-black text-white'} text-xl font-jost uppercase font-bold font-medium font-jost border border-[#D1D1D1] py-3 px-4`}
              >
                <ShareIcon /> <span>Share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProgramLink;

