import React, { useEffect, useState } from "react";

const QuantityCounter = ({ onChange, quantity }) => {
  const [countValue, setCountValue] = useState(quantity || 1);
  const handleIncrement = () => {
    setCountValue((prev) => ++prev);
  };

  const handleDecrement = () => {
    setCountValue((prev) => Math.max(prev - 1, 1));
  };

  useEffect(() => {
    if (onChange) onChange(countValue);
  }, [countValue]);

  return (
    <div className="flex items-center border border-[#555] rounded-md">
      <button
        onClick={() => handleDecrement()}
        className="w-[30px] h-[30px] flex items-center justify-center text-3xl font-jost font-normal leading-none  text-black"
      >
        -
      </button>
      <p className="w-[30px] h-[30px] flex items-center justify-center text-xl font-jost font-normal leading-none  text-black border-x border-x-[#555]">
        {countValue}
      </p>
      <button
        onClick={() => handleIncrement()}
        className="w-[30px] h-[30px] flex items-center justify-center text-xl font-jost font-normal leading-none  text-black"
      >
        +
      </button>
    </div>
  );
};

export default QuantityCounter;
