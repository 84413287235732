// import React, { useEffect } from "react";
// import PropTypes from "prop-types";
// import { Input as BaseInput } from "@mui/base/Input";
// import { Box, styled } from "@mui/system";

// function OTP({ separator, length, value, onChange }) {
//   const inputRefs = React.useRef(new Array(length).fill(null));

//   const focusInput = (targetIndex) => {
//     const targetInput = inputRefs.current[targetIndex];
//     targetInput.focus();
//   };

//   const selectInput = (targetIndex) => {
//     const targetInput = inputRefs.current[targetIndex];
//     targetInput.select();
//   };

//   const handleKeyDown = (event, currentIndex) => {
//     switch (event.key) {
//       case "ArrowUp":
//       case "ArrowDown":
//       case " ":
//         event.preventDefault();
//         break;
//       case "ArrowLeft":
//         event.preventDefault();
//         if (currentIndex > 0) {
//           focusInput(currentIndex - 1);
//           selectInput(currentIndex - 1);
//         }
//         break;
//       case "ArrowRight":
//         event.preventDefault();
//         if (currentIndex < length - 1) {
//           focusInput(currentIndex + 1);
//           selectInput(currentIndex + 1);
//         }
//         break;
//       case "Delete":
//         event.preventDefault();
//         onChange((prevOtp) => {
//           const otp =
//             prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
//           return otp;
//         });

//         break;
//       case "Backspace":
//         event.preventDefault();
//         if (currentIndex > 0) {
//           focusInput(currentIndex - 1);
//           selectInput(currentIndex - 1);
//         }

//         onChange((prevOtp) => {
//           const otp =
//             prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
//           return otp;
//         });
//         break;

//       default:
//         break;
//     }
//   };

//   const handleChange = (event, currentIndex) => {
//     const currentValue = event.target.value;
//     let indexToEnter = 0;

//     while (indexToEnter <= currentIndex) {
//       if (
//         inputRefs.current[indexToEnter].value &&
//         indexToEnter < currentIndex
//       ) {
//         indexToEnter += 1;
//       } else {
//         break;
//       }
//     }

//     onChange((prev) => {
//       const otpArray = prev.split("");
//       const lastValue = currentValue[currentValue.length - 1];
//       otpArray[indexToEnter] = lastValue;
//       return otpArray.join("");
//     });

//     if (currentIndex === length - 1 && currentValue !== "") {
//       inputRefs.current[currentIndex].blur();
//     } else if (currentValue !== "") {
//       focusInput(currentIndex + 1);
//     }
//   };

//   const handleClick = (event, currentIndex) => {
//     selectInput(currentIndex);
//   };

//   const handlePaste = (event, currentIndex) => {
//     event.preventDefault();
//     const clipboardData = event.clipboardData;

//     // Check if there is text data in the clipboard
//     if (clipboardData.types.includes("text/plain")) {
//       let pastedText = clipboardData.getData("text/plain");
//       pastedText = pastedText.substring(0, length).trim();
//       let indexToEnter = 0;

//       while (indexToEnter <= currentIndex) {
//         if (
//           inputRefs.current[indexToEnter].value &&
//           indexToEnter < currentIndex
//         ) {
//           indexToEnter += 1;
//         } else {
//           break;
//         }
//       }

//       const otpArray = value.split("");

//       for (let i = indexToEnter; i < length; i += 1) {
//         const lastValue = pastedText[i - indexToEnter] ?? " ";
//         otpArray[i] = lastValue;
//       }

//       onChange(otpArray.join(""));
//     }
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         gap: 1,
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       {new Array(length).fill(null).map((_, index) => (
//         <React.Fragment key={index}>
//           <BaseInput
//             className="h-8 w-8 sm:h-10 sm:w-10 xl:h-[70px] xl:w-[40px] flex-shrink-0 text-2xl md:text-3xl xl:text-[40px]"
//             slots={{
//               input: InputElement,
//             }}
//             aria-label={`Digit ${index + 1} of OTP`}
//             slotProps={{
//               input: {
//                 ref: (ele) => {
//                   inputRefs.current[index] = ele;
//                 },
//                 onKeyDown: (event) => handleKeyDown(event, index),
//                 onChange: (event) => handleChange(event, index),
//                 onClick: (event) => handleClick(event, index),
//                 onPaste: (event) => handlePaste(event, index),
//                 value: value[index] ?? "",
//               },
//             }}
//           />
//           {index === length - 1 ? null : separator}
//         </React.Fragment>
//       ))}
//     </Box>
//   );
// }

// OTP.propTypes = {
//   length: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired,
//   separator: PropTypes.node,
//   value: PropTypes.string.isRequired,
// };

// export default function OTPInput({ otpValue, onChange }) {
//   console.log("🚀 ~ OTPInput ~ otpValue:", otpValue);
//   const [otp, setOtp] = React.useState(otpValue.toString() || "");
//   useEffect(() => {
//     if (otp.length === 6) if (onChange) onChange(otp);
//     return () => {};
//   }, [otp]);

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         flexDirection: "column",
//         gap: 2,
//       }}
//     >
//       <OTP
//         separator={<span></span>}
//         value={otp || otpValue}
//         onChange={setOtp}
//         length={6}
//       />
//     </Box>
//   );
// }
// const InputElement = styled("input")(
//   ({ theme }) => `
//   width: 100%;
//   height: 100%;
//   font-weight: 400;
//   line-height: 1.5;
//   padding: 8px 0px;  text-align: center;
//   color:  #000;
//   background: #fff;
//   border-bottom: 1px solid #A9A9A9;
//   &:focus {
//     border-color: #000;
//   }
//   // firefox
//   &:focus-visible {
//     outline: 0;
//   }
// `
// );



import React, { useState, useRef, useEffect } from "react";

const OTPInput = ({ otpValue, onChange, disabled ,onEnter}) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    // Automatically focus on the first input field when the component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    otp[index] = value;
    setOtp([...otp]);
    onChange(otp.join(""));

    // Focus the next input box
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === "Backspace" || e.key === 'Enter') {
  //     if (!otp[index] && index > 0) {
  //       inputRefs.current[index - 1].focus();
  //     }
  //     onEnter();
  //   }
  // };

    const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (e.key === 'Enter') {
      if (otp.join("").length === 6) {
        onEnter(); // Only call onEnter if OTP is fully entered
      } else {
        // Display validation message if OTP is incomplete
        onEnter("Please enter the full OTP");
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    const otpArray = paste.slice(0, 6).split("");
    setOtp(otpArray);
    onChange(otpArray.join(""));
    otpArray.forEach((value, index) => {
      inputRefs.current[index].value = value;
    });
  };

  const handleReset = () => {
    setOtp(new Array(6).fill(""));
    onChange("");
    inputRefs.current[0].focus();
  };

  return (
    <div className="otp-input-container" onPaste={handlePaste}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={data}
          ref={(ref) => inputRefs.current[index] = ref}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="otp-input-box"
          disabled={disabled}
        />
      ))}
      <button onClick={handleReset} className="clear-otp-button">
        Clear OTP
      </button>
    </div>
  );
};

export default OTPInput;



