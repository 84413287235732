import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPlayer from "react-player";

const PhotoVideoSwiper = ({ info }) => {
  // console.log("🚀 ~ PhotoVideoSwiper ~ info:", info);
  const [openPhotoSwiper, setOpenPhotoSwiper] = useState(false);
  const handlePhotoSwiperOpen = () => setOpenPhotoSwiper(true);
  const handlePhotoSwiperClose = () => setOpenPhotoSwiper(false);

  const [swiperProgress, setSwiperProgress] = useState(0);

  const handleSwiperProgress = (swiper) => {
    if (swiper) {
      setSwiperProgress((swiper.progress * 100).toFixed(2));
    }
  };
  return (
    <>
      <div className="lg:px-4 relative">
        <button className="hidden lg:flex absolute top-1/2 left-1 -translate-y-1/2 z-10 w-[46px] h-[46px] rounded-full bg-[#EDEDED] items-center justify-center outline-none fifthSwiperPrev">
          <FaAngleLeft className="text-lg" />
        </button>
        <button className="hidden lg:flex absolute top-1/2 right-1 -translate-y-1/2 z-10 w-[46px] h-[46px] rounded-full bg-[#EDEDED] items-center justify-center outline-none fifthSwiperPrev">
          <FaAngleRight className="text-lg" />
        </button>
        <Swiper
          className="relative"
          spaceBetween={16}
          slidesPerView={1}
          centeredSlides
          navigation={{
            nextEl: ".fifthSwiperNext",
            prevEl: ".fifthSwiperPrev",
          }}
          modules={[Navigation]}
          loop
          breakpoints={{
            340: {
              slidesPerView: 1.5,
              centeredSlides: true,
            },
            540: {
              slidesPerView: 2.5,
            },
            650: {
              slidesPerView: 3.5,
            },
            992: {
              slidesPerView: 4,
              centeredSlides: false,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 20,
              centeredSlides: false,
            },
            1400: {
              slidesPerView: 6,
              spaceBetween: 25,
              centeredSlides: false,
            },
          }}
          autoplay={{
            delay: 5000,
          }}
        >
          {info?.photos_videos?.map((item, index) => {
            return (
              <SwiperSlide key={"photos_videos" + index}>
                <button
                  className="block h-[300px] w-full"
                  onClick={handlePhotoSwiperOpen}
                >
                  {item.type === "video" ? (
                    <ReactPlayer
                      url={item.url}
                      controls
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <img src={item.url} className="h-full w-full" alt="" />
                  )}
                </button>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <Modal open={openPhotoSwiper}>
        <Box>
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center p-4 xl:p-0">
            <div className="max-w-[1506px] w-full py-5 pl-4 sm:pl-9 lg:pl-[25px] pe-2.5">
              <button
                className="text-black text-3xl p-3 absolute right-8 top-0 z-30"
                onClick={handlePhotoSwiperClose}
              >
                <MdOutlineClose />
              </button>
              <div className="max-h-[80vh] h-[400px] sm:h-[600px] lg:h-[700px] 2xl:h-[920px] px-3 md:px-20 xl:px-[200px] relative">
                <button className=" absolute top-1/2 left-0 -translate-y-1/2 z-10 flex items-center justify-center outline-none photoSwiperPrev">
                  <FaAngleLeft className="text-[32px] md:text-4xl lg:text-6xl 2xl:text-8xl font-medium text-white" />
                </button>
                <button className=" absolute top-1/2 right-0 -translate-y-1/2 z-10 flex items-center justify-center outline-none photoSwiperNext">
                  <FaAngleRight className="text-[32px] md:text-4xl lg:text-6xl 2xl:text-8xl font-medium text-white" />
                </button>
                <Swiper
                  onProgress={handleSwiperProgress}
                  className="h-full"
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView="1.6"
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  navigation={{
                    nextEl: ".photoSwiperNext",
                    prevEl: ".photoSwiperPrev",
                  }}
                  modules={[EffectCoverflow, Navigation]}
                  autoplay={{
                    delay: 1000,
                  }}
                  breakpoints={{
                    340: {
                      slidesPerView: 1.2,
                    },
                    650: {
                      slidesPerView: 1.6,
                    },
                  }}
                >
                  <div
                    className="absolute top-0 left-0 h-1 bg-white w-full z-10"
                    style={{ width: `${swiperProgress}%` }}
                  />

                  {info?.photos_videos?.map((item, index) => {
                    return (
                      <SwiperSlide
                        className="reletive"
                        key={"photos_videos" + index}
                      >
                        <button
                          className="block 2xl:h-[448px] w-full"
                          onClick={handlePhotoSwiperOpen}
                        >
                          {item.type === "video" ? (
                            <ReactPlayer
                              url={item.url}
                              controls
                              width="100%"
                              height="100%"
                            />
                          ) : (
                            <img
                              src={item.url}
                              className="h-full w-full"
                              alt=""
                            />
                          )}
                        </button>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PhotoVideoSwiper;
