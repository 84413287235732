import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { PlusIcon, MinusIcon } from "../assets/SvgIcons";
import { Typography } from "@mui/material";

const Faq = ({ faqData }) => {
  const [expandedId, setExpandedId] = useState(null);

  const handleAccordionChange = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      {faqData?.map((item, index) => {
        const id = "faqs" + index;
        return (
          <Accordion
            key={id}
            className="!shadow-none !my-2.5 border border-[#C2C2C2] !rounded-[10px] overflow-hidden rounded-10"
            expanded={expandedId === id}
            onChange={() => handleAccordionChange(id)}
          >
            <AccordionSummary
              className="h-[60px] accordion-btn"
              expandIcon={expandedId === id ? <MinusIcon /> : <PlusIcon />}
              aria-controls={id + "-content"}
              id={id + "-header"}
            >
              <Typography
                variant="body1"
                className="text-base md:text-xl leading-5 font-medium font-Roboto text-[#393939]"
              >
                {item?.page_title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-4 border-t border-[#c2c2c2]">
              <p className="text-sm font-Roboto text-[#393939]">
                {item?.page_description}
              </p>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default Faq;
