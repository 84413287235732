import React from 'react'
import CustomerReview from './CustomerReview'
import { Rating } from '@mui/material'
import { FaStar } from 'react-icons/fa6'

const CustomerReviewSection = ({productInfo, BorderLinearProgress}) => {
  return (
    <div className="max-w-[1654px] mx-auto flex flex-wrap justify-between py-8 md:pb-10 xl:pb-[60px] border-t border-[#ACACAC] gap-y-5 px-3">
          <div className="w-full lg:w-[43%] px-3">
            <div>
              <h3 className="text-xl md:text-2xl xl:text-3xl  font-medium !leading-[1.4] text-[#242424] font-jost mb-5 md:mb-8 xl:mb-[30px]">
                Customer reviews
              </h3>
              <div className="flex flex-col lg:flex-row gap-5 items-center border-2 shadow-[0px_1px_1px_0px_#00000040] p-3 md:p-[26px] rounded-xl xl:rounded-[20px] mb-10">
                <div className="w-4/12 sm:w-4/12 lg:pe-8">
                  <h4 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-[100px] text-center font-medium !leading-tight text-black">
                    {parseFloat(
                      productInfo?.product_rating?.avg_rating
                    ).toFixed(1)}
                  </h4>
                  <Rating
                    value={parseFloat(
                      productInfo?.product_rating?.avg_rating
                    ).toFixed(1)}
                    readOnly
                    icon={<FaStar className="text-lg text-[#F8C83A]" />}
                    emptyIcon={<FaStar className="text-lg text-[#D8D8D8]" />}
                    className="!mx-auto !w-full !justify-center !gap-1.5"
                    //
                  />
                </div>
                <div className="w-8/12 items-center block gap-4 lg:ps-2 lg:border-s-[2px] border-[#CFCFCF] ">
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      5
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["5"]
                        )}
                        // className="w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["5"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      4
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["4"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["4"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      3
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["3"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["3"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      2
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["2"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["2"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      1
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["1"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["1"]
                      )}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-3">
            <div>
              <h3 className="text-xl md:text-2xl xl:text-3xl font-medium !leading-[1.4] text-[#242424] font-jost mb-5 md:mb-8 xl:mb-[30px]">
                Top reviews from india
              </h3>
              <div
                className="grid gap-5 xl:gap-[30px] max-h-[400px] overflow-scroll p-5"
                style={{ scrollbarWidth: "none" }}
              >
                <CustomerReview />
              </div>
            </div>
          </div>
        </div>
  )
}

export default CustomerReviewSection
