import React from "react";

const DescriptionSection = ({ data }) => {
  return (
    <section className="my-10 md:mt-[50px] md:mb-[60px] ">
      <div className="max-w-[1600px] mx-auto px-3">
        <div className="text-center mb-5 md:mb-8 xl:mb-14">
          <h2 className="text-xl md:text-2xl xl:text-[35px] !leading-none font-normal font-jost pb-5 xl:pb-9 border-b border-black inline-block ">
            {data?.DISCRIPTION?.[0]?.main_title}
          </h2>
        </div>

        {data?.DISCRIPTION?.map((item, index) => {
          if (index !== 0) {
            return (
              <div key={"details" + index}>
                <h4 className="text-lg md:text-2xl leading-5 md:leading-7 font-normal font-jost text-black mb-4 md:mb-5">
                  {item?.title}
                </h4>
                <p className="text-[15px] leading-5 font-normal font-Roboto text-[#7A7A7A] mb-3 md:mb-4 xl:mb-[22px]">
                  {item?.description}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </section>
  );
};

export default DescriptionSection;
