import React, { useContext, useRef, useState } from "react";
import { CartIcon } from "../../assets/SvgIcons";
import { Link, useNavigate } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import BagCard from "../bag/BagCard";

import { MdShoppingCart } from "react-icons/md";

import { CartContext } from "../../context/CartContext";

const CartButton = () => {
  const [showBag, setShowBag] = useState(false);
  const navigate = useNavigate();
  const priceSummaryRef = useRef(null);

  const toggleDrawer = (newOpen) => () => {
    setShowBag(newOpen);
  };

  const { cartInfo, fetchCartItems } = useContext(CartContext);

  const handleViewDetails = () => {
    if (priceSummaryRef.current) {
      priceSummaryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <button
        className="flex items-center gap-1 text-white outline-0"
        onClick={toggleDrawer(true)}
      >
        <span className="relative inline-block w-[26px] h-[26px]">
          <CartIcon />
          {cartInfo?.cartcount !== undefined &&
            cartInfo?.cartcount !== null && (
              <span className="cart-num absolute -right-0.5 -bottom-0.5 h-4 w-4 rounded-full bg-[#FF344B] grid place-content-center text-xs font-normal font-Roboto">
                {cartInfo.cartcount}
              </span>
            )}
        </span>
        <span className="hidden xl:inline-block text-lg font-medium capitalize leading-4 font-jost">
          Cart
        </span>
      </button>
      <Drawer open={showBag} onClose={toggleDrawer(false)} anchor="right">
        <Box
          role="presentation"
          className="max-w-[300px] lg:!max-w-[450px] w-screen"
        >
          <div className="w-screen max-w-[300px] lg:max-w-[450px] h-full bg-white absolute top-0 right-0 p-3 lg:p-5">
            <div className="bag-header">
              <button
                className="w-10 aspect-square flex items-center justify-center"
                onClick={() => {
                  setShowBag(false);
                }}
              >
                <IoIosClose size={40} />
              </button>
              <h4 className="my-3 2xl:my-[30px] text-xl lg:text-2xl 2xl:text-[40px] font-medium !leading-none">
                Bag
              </h4>
            </div>
            {cartInfo?.cartcount ? (
              <>
                <div className="h-[calc(100%-157px)] lg:h-[calc(100%-192px)] 2xl:h-[calc(100%-244px)] overflow-auto mb-6 scrollbar">
                  <div className="grid gap-[15px] mb-6">
                    {cartInfo?.cartdata?.map((item, index) => {
                      return (
                        <BagCard
                          key={"cartItem" + item?.id}
                          info={item}
                          cartId={cartInfo?.cart_id}
                          fetchCartItems={fetchCartItems}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="flex items-center gap-3 justify-between">
                  <div>
                    <h5 className="text-lg font-medium leading-8 ">
                      ₹{cartInfo?.total_net_amount || 0}
                    </h5>
                  </div>
                  <Link
                    to={"/choose-address"}
                    onClick={() => setShowBag(false)}
                    className="bg-[#E9B159] p-3 lg:p-7 max-w-[360px] sm:w-[240px] lg:w-[360px] text-lg lg:text-2xl font-medium !leading-none text-center text-white"
                  >
                    Proceed to Buy
                  </Link>
                </div>
              </>
            ) : (
              <div className="p-4 flex items-center flex-col justify-center">
                <MdShoppingCart className="text-7xl my-4" />
                <p>Your cart is empty !</p>
                <button
                  className="mt-5 bg-black text-white rounded px-4 py-2"
                  onClick={() => {
                    setShowBag(false);
                    navigate("/product-page");
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            )}
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default CartButton;
