import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import sucessImage from "../assets/images/tick.png";
import { useNavigate } from "react-router-dom";
const SuccessModal = ({ open, message }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} style={{ padding: "20px 20px" }}>
      <DialogTitle className="">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={sucessImage}
            alt="Order Success"
            style={{ width: "100px", height: "auto", marginBottom: "10px" }}
            loading="lazy"
          />
          <h1 className="text-xl xl:text-[22px] font-medium !leading-none mb-1 lg:mb-2.5">
            Order Successfully
          </h1>
        </div>
      </DialogTitle>
      <DialogContent>
        <p className="text-center">{message}</p>
        <p className="text-center">Your order has been placed successfully. </p>
        <p className="text-center">
          you can see the status of the order at any time
        </p>
      </DialogContent>
      <div className="mx-5">
        <button
          className="w-full bg-[#E9B159] p-1 sm:p-3 xl:p-7 text-lg lg:text-2xl font-medium leading-10 text-center text-white rounded-md mt-2.5"
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          Back to Home
        </button>
      </div>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
