import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";

const Breadcrumb = lazy(() => import("../components/Breadcrumb"));
const CertificateCard = lazy(() => import("../components/CertificateCard"));
const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));

const CertificatePage = () => {
  const [certificateInfo, setCertificateInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const breadcrumbArray = [
    <Link
      to="/"
      key="home"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to="/about-us"
      key="about-us"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      About Us
    </Link>,
    <p
      key="certificate"
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      Certificate
    </p>,
  ];

  const fetchCertificateInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get("allcertificates");
      if (data?.STATUS === 200) {
        setCertificateInfo(data.DATA);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE ||
          err?.message ||
          "Failed to fetch information"
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCertificateInfo();
  }, [fetchCertificateInfo]);

  if (loading) {
    return (
      <div className="loader-overlay">
        <div className="loader-container">
          <div className="loader-circle-9">
            Kapoor
            <span></span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <>
          {" "}
          <div className="loader-overlay">
            <div className="loader-container">
              <div className="loader-circle-9">
                Kapoor
                <span></span>
              </div>
            </div>
          </div>
        </>
      }
    >
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div className="my-10 xl:my-14">
        <img
          src="/images/logo-gold.png"
          className="w-[200px] mx-auto"
          alt="Kapoor Logo"
          loading="lazy"
        />
      </div>
      <div className="max-w-[1632px] mx-auto px-3 mb-10 md:mb-20 xl:mb-24">
        <div className="flex flex-wrap justify-center gap-y-5 lg:gap-y-10 2xl:gap-y-14 gap-x-5">
          {certificateInfo?.images?.map((item, index) => (
            <CertificateCard imageUrl={item} key={`certificate-${index}`} />
          ))}
        </div>
      </div>
      <Footer />
    </Suspense>
  );
};

export default CertificatePage;
