import React from "react";
import { Breadcrumbs } from "@mui/material";
import { FaChevronRight } from "react-icons/fa6";
const Breadcrumb = ({ list }) => {
  return (
    <div className="bg-[white]">
      <div className="py-2 max-w-[1804px] w-full px-3 mx-auto">
        <Breadcrumbs
          separator={<FaChevronRight className="text-sm " color="#A36300" />}
          aria-label="breadcrumb"
        >
          {list}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default Breadcrumb;
