import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };

  return (
    <div className="max-w-[1804px] w-full h-[100vh] flex justify-center items-center mx-auto">
      <div>
        <img
          src="/images/error-img.png"
          className="w-[400px] mb-4"
          loading="lazy"
        />
        <div className="text-center">
          <h2 className="font-bold text-3xl">So Sorry!</h2>
          <h3 className="text-xl capitalize">
            The Page you are looking for cannot be found{" "}
          </h3>
        </div>
        <div className="flex justify-center items-center mt-5">
          <button
            onClick={handleNavigate}
            className="border-2 border-[#e9b15a] px-[30px] py-3 text-[#e9b15a] rounded-[20px]"
          >
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
