import React, { useEffect, useState, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";

const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Breadcrumb = lazy(() => import("../components/Breadcrumb"));
const Footer = lazy(() => import("../components/Footer"));
const ArticleCard = lazy(() => import("../components/ArticleCard"));

const BlogPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      key="1"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      Blog/Article
    </p>,
  ];

  const [blogInfo, setBlogInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogInfo = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("Blog_Article");
      if (data && data?.STATUS === 200) {
        setBlogInfo(data?.DATA || []);
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.MESSAGE ||
          err?.message ||
          "Failed to fetch Information"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogInfo();
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog - Kapoorlehengasaree</title>
        <meta property="og:title" content="Blog" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kapoorlehengasaree.com/blog" />
        <meta
          name="description"
          property="og:description"
          content="Explore the latest updates and trends in women's fashion on our blog. Stay informed with Kapoor Lehenga Saree."
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:title" content="Blog" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="Explore the latest updates and trends in women's fashion on our blog. Stay informed with Kapoor Lehenga Saree."
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/blog" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />
        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <div className="loader-overlay">
            <div className="loader-container">
              <div className="loader-circle-9">
                Kapoor
                <span></span>
              </div>
            </div>
          </div>
        }
      >
        <TopBar />
        <Header />
        <Breadcrumb list={breadcrumbArray} />
        <div className="max-w-[1600px] mx-auto px-3 mt-10 mb-14">
          <div className="flex flex-wrap -mx-3 gap-y-5 lg:gap-y-10">
            {blogInfo.map((item, index) => (
              <div key={index} className="w-full md:w-6/12 px-3">
                <ArticleCard blogData={item} />
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </Suspense>
    </>
  );
};

export default BlogPage;
