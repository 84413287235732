import React, { useContext, useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { TbHeartPlus } from "react-icons/tb";
import { Link } from "react-router-dom";
import addToWishlist from "../utils/addToWishlist";
import removeFromWishlist from "../utils/removeFromWishlist";
import ProductImageSlider from "./ProductImageSlider";
import toast from "react-hot-toast";
import { STORAGE } from "../config/config";
import axios from "axios";
import { LoginDrawerContext } from "../context/LoginDrawerContext";
import { CartContext } from "../context/CartContext";
import { WishlistContext } from "../context/WishlistContext";

const SuggestionCard = ({ imageUrl, info }) => {
  const productName = info?.product_name.replace(/\s+/g, "-").toLowerCase();
  // .replace(/-+$/, '');
  const [isWishlisted, setIsWishlisted] = useState(false);
  const { fetchCartItems } = useContext(CartContext);
  const { fetchWishlistItem, removeFromWishlist } = useContext(WishlistContext);

  const { setShowLogin } = useContext(LoginDrawerContext);

  const isLoggedIn = localStorage.getItem(STORAGE?.ISLOGIN);

  const truncateProductName = (name) => {
    if (name.length > 18) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  useEffect(() => {
    if (info?.is_wishlist) setIsWishlisted(true);
    else setIsWishlisted(false);
  }, [info]);

  const handleAddToCartClick = async (id) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("addtocart", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        is_mobile: "0",
        product_id: id,
        product_quantity: 1,
        stching: "unstitched",
        is_admin: "0",
        user_id: userProfile?.id,
      });
      if (data && data?.STATUS === 200) {
        fetchCartItems();
        fetchWishlistItem();
        // console.log("data::", data.DATA);
        // console.log("click");
        toast.success(data?.MESSAGE || "Product added to cart.");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Failed to add to cart.");
    }
  };

  return (
    <Link
      to={`/product/${info.product_name}`}
      state={{ id: info?.id, name: info.product_name }}
    >
      <div className="group transition hover:shadow-[0px_0px_10px_0px_#0000004D]">
        <div className="aspect-[2/3] relative">
          <div className="h-full bg-no-repeat bg-left-top">
            <ProductImageSlider
              imageList={info?.product_images || info?.product_image}
            />
          </div>
          <div className="absolute z-10 left-0 bottom-2.5 w-full px-4 opacity-0 transition group-hover:opacity-100">
            <button
              className="w-full border-none text-sm md:text-base 2xl:text-lg font-normal px-4 py-2 md:py-3 bg-white hover:bg-[#E9B159] text-black block text-center uppercase border border-[#D3D3D3] rounded"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isLoggedIn) {
                  handleAddToCartClick(info?.id || info?.product_id);
                } else {
                  setShowLogin(true);
                }
              }}
            >
              ADD TO CART
            </button>
          </div>
          <div className="absolute right-2 top-5 z-20 opacity-0 transition group-hover:opacity-100">
            {isWishlisted ? (
              <button
                type="button"
                className="flex items-center justify-center bg-white p-2 rounded-full"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const removed = removeFromWishlist(
                    info?.id || info?.product_id
                  );
                  if (removed) setIsWishlisted(false);
                }}
              >
                <FaHeart className="text-[#FF3F3F] text-lg" />
              </button>
            ) : (
              <button
                type="button"
                className="flex items-center justify-center bg-white p-2 rounded-full"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (isLoggedIn) {
                    // handleAddToCart(info?.id || info?.product_id);
                    const added = addToWishlist(info?.id || info?.product_id);
                    if (added) setIsWishlisted(true);
                  } else {
                    // toast.dismiss()
                    // toast.error("Please login to add to wishlist");
                    setShowLogin(true);
                  }
                }}
              >
                <TbHeartPlus className="text-black text-lg" />
                {/* <LikePlus /> */}
              </button>
            )}
          </div>
          <div className="absolute z-10 right-1 -top-[6px] transition group-hover:opacity-0">
            {info?.product_discount > 0 && (
              <div
                className="grid place-content-center w-[61px] h-[42px] bg-no-repeat bg-cover font-Roboto text-center"
                style={{ backgroundImage: "url(/images/offer-card-bg.png)" }}
              >
                <h5 className="text-xs sm:text-sm font-black leading-[14px] sm:leading-[17px] text-white">
                  {parseInt(info?.product_discount)}%
                </h5>
                <span className="text-[8px] font-semibold leading-[9px] text-white">
                  DISCOUNT
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="p-2.5 sm:p-4 sm:pb-5">
          <h3 className="text-sm sm:text-base font-normal !leading-[18px] mb-0.5 capitalize">
            {truncateProductName(info?.product_name || "")}
          </h3>
          <div className="flex items-center gap-2">
            <span className="text-[13px] font-semibold leading-[17px] text-[#505050] line-through">
              ₹{info?.product_mrp}
            </span>
            <span className="text-sm sm:text-base font-semibold text-[#03A685]">
              ₹{info?.product_price}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SuggestionCard;
