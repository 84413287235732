import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import OrderCard from "../components/OrderCard";
import axios from "axios";
import { STORAGE } from "../config/config";
import toast from "react-hot-toast";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

export const ReturnProduct = () => {
  const [returnProductInfo, setReturnProductInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fatchreturnProduct = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    console.log("retrun", { user_id: userProfile?.id });
    try {
      setLoading(true);
      const res = await axios.post("myreturn", {
        user_id: userProfile?.id,
      });
      // console.log(res.data);
      if (res.data.STATUS == 200) {
        setReturnProductInfo(res.data.DATA);
      } else if (res.data.STATUS == 400) {
      }
      // console.log("returnProductInfo:", returnProductInfo);
    } catch {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fatchreturnProduct();
  });

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to="/returnpage"
      underline="hover"
      key="2"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      return Product
    </Link>,
  ];
  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div className="flex flex-col gap-3 pb-4">
        <OrderCard returnInfo={returnProductInfo} />
      </div>
      <Footer />
    </>
  );
};
