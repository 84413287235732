import AboutCompanyPage from "../pages/AboutCompanyPage";
import AboutUsPage from "../pages/AboutUsPage";
import AccountPage from "../pages/AccountPage";
import AffiliateProgramPage from "../pages/AffiliateProgramPage";
import BlogPage from "../pages/BlogPage";
import CertificatePage from "../pages/CertificatePage";
import ChooseAddressPage from "../pages/ChooseAddressPage";
import ContactUsPage from "../pages/ContactUsPage";
import DeleteUserAccountPage from "../pages/DeleteUserAccountPage";
import FaqsPage from "../pages/FaqsPage";
import FranchisePage from "../pages/FranchisePage";
import HomePage from "../pages/HomePage";
import MyEarnPage from "../pages/MyEarnPage";
import MyOrderpage from "../pages/MyOrderpage";
import OrderDetailsPage from "../pages/OrderDetailsPage";
import OurStorePage from "../pages/OurStorePage";
import PrivancyPolicy from "../pages/Privancy police";
import ProductDetailsPage from "../pages/ProductDetailsPage";
import ProductPage from "../pages/ProductPage";
import RefundsAndCancellations from "../pages/refundandcancallation";
import { ReturnProduct } from "../pages/ReturnProduct";
import SubscribeNewLetterPage from "../pages/SubscribeNewLetterPage";
import SuccessStoriesPage from "../pages/SuccessStoriesPage";
import TeamDetailsPage from "../pages/TeamDetailsPage";
import TermAndConditionPage from "../pages/TermAndConditionPage";
import VideoAppointmentPage from "../pages/VideoAppointmentPage";
import WishlistPage from "../pages/WishlistPage";

export const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/home", element: <HomePage /> },
  { path: "/wishlist", element: <WishlistPage /> },
  { path: "/subscribe-new-letter", element: <SubscribeNewLetterPage /> },
  { path: "/blog", element: <BlogPage /> },
  { path: "/video-appointment", element: <VideoAppointmentPage /> },
  { path: "/contact-us", element: <ContactUsPage /> },
  { path: "/about-us", element: <AboutUsPage /> },
  { path: "/about-company", element: <AboutCompanyPage /> },
  { path: "/success-stories", element: <SuccessStoriesPage /> },
  { path: "/team-detail", element: <TeamDetailsPage /> },
  { path: "/our-certificate", element: <CertificatePage /> },
  { path: "/franchise", element: <FranchisePage /> },
  { path: "/account", element: <AccountPage /> },
  { path: "/affiliate-program", element: <AffiliateProgramPage /> },
  { path: "/my-earn", element: <MyEarnPage /> },
  { path: "/orders-details", element: <OrderDetailsPage /> },
  { path: "/returnpage", element: <ReturnProduct /> },
  { path: "/choose-address", element: <ChooseAddressPage /> },
  { path: "/product-page", element: <ProductPage /> },
  // { path: "/product/:id/:name", element: <ProductDetailsPage /> },
  { path: "/product/:name", element: <ProductDetailsPage /> },
  { path: "/our-store", element: <OurStorePage /> },
  { path: "/term-and-condition", element: <TermAndConditionPage /> },
  { path: "/privancypolicy", element: <PrivancyPolicy /> },
  { path: "/return-cancellation", element: <RefundsAndCancellations /> },
  { path: "/my-order", element: <MyOrderpage /> },
  { path: "/orders-details/:orderId", element: <OrderDetailsPage /> },
  { path: "/faqs", element: <FaqsPage /> },
  { path: "/delete-account", element: <DeleteUserAccountPage /> },
];
