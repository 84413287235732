import React, { useEffect, useState } from "react";
import { FaStar, FaXmark } from "react-icons/fa6";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { Rating } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";

const CustomerReview = ({ customClass }) => {
  const [review, setReviews] = useState([]);
  const { id } = useParams();
  const [selectedReview, setSelectedReview] = useState({});

  const fetchReview = () => {
    axios
      .post("allreviews", {
        product_id: id,
      })
      .then((response) => {
        setReviews(response.data.DATA);
      })
      .catch((error) => {
        // console.error("There was an error fetching the reviews!", error);
      });
  };

  useEffect(() => {
    fetchReview();
  },[id]);

  const [openImageGallery, setOpenImageGallery] = useState(false);
  const handleImageGalleryOpen = (review) => {
    setOpenImageGallery(true);
    setSelectedReview(review);
  };

  const handleImageGalleryClose = () => setOpenImageGallery(false);

  if (review.length === 0) {
    return (
      <div className="flex justify-center items-center h-40">
        <p className="text-lg">No reviews available.</p>
      </div>
    );
  }

  return (
    <>
      {review.length > 0 &&
        review.map((data, index) => {
          return (
            <div className="flex items-start gap-4" key={index}>
              <div className="flex-shrink-0 h-8 w-8 sm:h-16 sm:w-16 rounded-full overflow-hidden border border-[#E8E8E8]">
                <img
                  src={data?.user_profile}
                  className="h-full w-full object-cover object-top"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div>
                <h4 className="xs:text-lg font-semibold !leading-[1.1] line-clamp-1 mb-1.5">
                  {data?.user_name}
                </h4>
                <div className="flex items-center gap-2">
                  <Rating
                    value={parseFloat(data?.product_review_star).toFixed(1)}
                    readOnly
                    icon={<FaStar className="text-lg text-[#F8C83A]" />}
                    emptyIcon={<FaStar className="text-lg text-[#D8D8D8]" />}
                    className=" !gap-1.5"
                  />
                  <p className="sm:text-lg !leading-tight text-[#8D8C8C]">
                    {moment(data?.created_at).format("DD MMM, YYYY")}
                  </p>
                </div>
                <p className="font-jost sm:text-2xl font-normal !leading-tight text-[#555] mt-6">
                  {data?.product_review_message}
                </p>
                <div
                  className={`flex flex-nowrap gap-3 md:gap-3.5 mt-4 md:mt-7 overflow-x-auto ${customClass}`}
                >
                  <button
                    className="flex gap-1"
                    onClick={() => handleImageGalleryOpen(data)}
                  >
                    {data?.review_images.map((media, mediaIndex) =>
                      media.endsWith(".mp4") ||
                      media.endsWith(".avi") ||
                      media.endsWith(".mov") ||
                      media.endsWith(".webm") ? (
                        <video
                          key={mediaIndex}
                          className="flex-shrink-0 w-28 h-24 md:w-30 md:h-30 object-cover object-top"
                          controls
                        >
                          <source src={media} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          key={mediaIndex}
                          className="flex-shrink-0 w-28 h-24 md:w-30 md:h-30 object-cover object-top"
                          src={media}
                          alt=""
                        />
                      )
                    )}
                  </button>
                </div>
              </div>
            </div>
          );
        })}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openImageGallery}
        onClose={handleImageGalleryClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <div className="fixed inset-0 px-4 xl:p-0 overflow-y-auto flex items-center justify-center z-50">
          <div className="w-full h-full md:w-auto md:h-auto max-w-[850px] max-h-[90vh] mx-auto bg-white overflow-hidden relative z-50">
            <div className="bg-[#EAEAEA] flex items-center justify-between px-4 sm:px-5 xl:pl-10 xl:pr-[30px] py-3 sm:py-[18px]">
              <div className="flex items-center gap-[15px]">
                <span>
                  <BsFillGrid3X3GapFill className="text-lg md:text-xl" />
                </span>
                <h3 className="text-lg md:text-xl !leading-none font-jost font-medium text-black">
                  View image Gallery
                </h3>
              </div>
              <button className="" onClick={handleImageGalleryClose}>
                <FaXmark className="text-lg md:text-xl text-[#716C6C]" />
              </button>
            </div>

            <div className="flex flex-wrap pt-6 px-3 md:pr-6 pb-[30px] md:pl-[30px] gap-y-5 overflow-y-auto h-full">
              <div className="w-full md:w-[55%] md:pr-[15px] h-[50vh] md:h-auto">
                <div className="relative h-full">
                  <button className="absolute top-1/2 left-0 -translate-y-1/2 z-20 flex items-center justify-center outline-none photoSwiperPrev">
                    <FaAngleLeft className="text-[32px] md:text-4xl lg:text-7xl font-medium text-white" />
                  </button>
                  <button className="absolute top-1/2 right-0 -translate-y-1/2 z-20 flex items-center justify-center outline-none photoSwiperNext">
                    <FaAngleRight className="text-2xl md:text-4xl lg:text-7xl font-medium text-white" />
                  </button>
                  <Swiper
                    className="relative h-full z-10"
                    slidesPerView={1}
                    navigation={{
                      nextEl: ".photoSwiperNext",
                      prevEl: ".photoSwiperPrev",
                    }}
                    modules={[Navigation]}
                  >
                    {selectedReview?.review_images?.map((media, mediaIndex) => (
                      <SwiperSlide key={mediaIndex} className="!h-full z-10">
                        {media.endsWith(".mp4") ||
                        media.endsWith(".avi") ||
                        media.endsWith(".mov") ||
                        media.endsWith(".webm") ? (
                          <video
                            className="h-full w-full object-cover object-top"
                            controls
                          >
                            <source src={media} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={media}
                            className="h-full w-full object-cover object-top"
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className="w-full md:w-[45%] md:pl-[15px] h-[50vh] md:h-auto overflow-y-auto">
                <div className="mb-4 md:mb-[30px]">
                  <div className="flex items-center gap-4 mb-3 md:mb-6">
                    <div className="flex-shrink-0 w-8 h-9 md:h-16 md:w-16 rounded-full overflow-hidden">
                      <img
                        src={selectedReview?.user_profile}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4 className="xs:text-lg md:text-22px font-semibold !leading-tight line-clamp-1 mb-1.5">
                        {selectedReview?.user_name}
                      </h4>
                      <div className="flex flex-wrap items-center gap-2">
                        <Rating
                          value={parseFloat(
                            selectedReview?.product_review_star
                          ).toFixed(1)}
                          readOnly
                          icon={<FaStar className="text-lg text-[#F8C83A]" />}
                          emptyIcon={
                            <FaStar className="text-lg text-[#D8D8D8]" />
                          }
                          className="!gap-1.5"
                        />
                        <p className="text-sm !leading-tight text-[#8D8C8C]">
                          {moment(selectedReview?.created_at).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="sm:text-lg md:text-xl xl:text-2xl 2xl:text-3xl text-[#404040] font-normal">
                    {selectedReview?.product_review_message}
                  </p>
                </div>
                <h4>Image in this review</h4>
                <div className="flex flex-nowrap gap-3 md:gap-5 overflow-x-auto">
                  {selectedReview?.review_images?.map((media, mediaIndex) => (
                    <div
                      key={mediaIndex}
                      className="flex-shrink-0 h-30 w-20 cursor-pointer border-2.5 border-transparent hover:border-black"
                    >
                      {media.endsWith(".mp4") ||
                      media.endsWith(".avi") ||
                      media.endsWith(".mov") ||
                      media.endsWith(".webm") ? (
                        <video
                          className="h-full w-full object-cover object-top"
                          controls
                        >
                          <source src={media} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={media}
                          className="h-full w-full object-cover object-top"
                          alt="kapoor-shop"
                          loading="lazy"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CustomerReview;
