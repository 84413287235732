import React, { useState, useRef } from "react";
import {
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaAngleUp,
} from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IoClose } from "react-icons/io5";

const ProductDetailsSlider = ({ images }) => {
  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbSwiper, setThumbSwiper] = useState(null);
  const [activeMainIndex, setActiveMainIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const zoomRef = useRef(null);

  const handleMainSlideChange = (swiper) => {
    setActiveMainIndex(swiper.activeIndex);
    if (thumbSwiper && thumbSwiper !== null) {
      const thumbSlidesInView = thumbSwiper.params.slidesPerView;
      const thumbIndexInView = swiper.activeIndex - thumbSlidesInView / 2;
      const thumbIndexMax = images.length - thumbSlidesInView;
      const newThumbIndex =
        thumbIndexInView < 0
          ? 0
          : thumbIndexInView > thumbIndexMax
          ? thumbIndexMax
          : thumbIndexInView;
      thumbSwiper.slideTo(newThumbIndex);
    }
  };

  const handleThumbClick = (index) => () => {
    mainSwiper.slideTo(index);
    setActiveMainIndex(index);
  };

  const handlePrev = () => {
    if (activeMainIndex > 0) {
      mainSwiper.slideTo(activeMainIndex - 1);
      setActiveMainIndex(activeMainIndex - 1);
    }
  };

  const handleNext = () => {
    if (activeMainIndex < images.length - 1) {
      mainSwiper.slideTo(activeMainIndex + 1);
      setActiveMainIndex(activeMainIndex + 1);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsZoomed(false); // Reset zoom when closing modal
  };

  const toggleZoom = () => setIsZoomed(!isZoomed);

  const handleMouseMove = (e) => {
    if (zoomRef.current && isZoomed) {
      const { left, top, width, height } =
        zoomRef.current.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;
      zoomRef.current.style.transformOrigin = `${(x / width) * 100}% ${
        (y / height) * 100
      }%`;
    }
  };

  return (
    <>
      <div className="flex sm:h-[700px] lg:h-[700px] xl:h-[700px] 2xl:h-[900px] object-cover">
        <div className="w-[13%] hidden sm:block">
          <div className="h-[calc(100%-32px)]">
            <Swiper
              onSwiper={setThumbSwiper}
              className="!h-full"
              spaceBetween={12}
              slidesPerView={4}
              direction="vertical"
              modules={[FreeMode, Thumbs]}
            >
              {images?.map((item, index) => (
                <SwiperSlide key={"thumb-" + index}>
                  <div
                    className={`h-full w-full cursor-pointer${
                      activeMainIndex === index
                        ? "border-[#e9b159]"
                        : " border-transparent"
                    } `}
                    onClick={handleThumbClick(index)}
                  >
                    <img
                      src={item}
                      className="h-full w-full object-cover"
                      alt="kapoor-shop"
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {images.length >= 4 && (
              <div className="flex items-center">
                <button
                  className="flex items-center justify-center w-1/2 bg-black text-white h-8"
                  onClick={handlePrev}
                >
                  <FaAngleUp />
                </button>
                <button
                  className="flex items-center justify-center w-1/2 bg-black text-white h-8"
                  onClick={handleNext}
                >
                  <FaAngleDown />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="w-full sm:w-[calc(100%-13%)] sm:pl-3 lg:h-[calc(100%)] md:h-[calc(100%)]">
          <Swiper
            onSlideChange={handleMainSlideChange}
            onSwiper={setMainSwiper}
            className="h-full"
            spaceBetween={10}
            navigation={true}
            pagination={{ clickable: true }}
            modules={[FreeMode, Navigation, Thumbs, Pagination]}
          >
            {images?.map((item, index) => (
              <SwiperSlide key={"main-" + index}>
                <div
                  className="h-full w-full flex items-center justify-center cursor-crosshair"
                  onClick={handleOpen}
                >
                  <img
                    src={item}
                    className="h-full w-full object-cover"
                    alt="kapoor-shop"
                    loading="lazy"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* Fullscreen Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1300,
          }}
        >
          {/* Image with Zoom */}
          <div
            className="w-full max-w-[90%] h-full flex items-center justify-center relative"
            onMouseMove={handleMouseMove}
          >
            <img
              ref={zoomRef}
              src={images[activeMainIndex]}
              className={`w-auto max-h-full object-contain transition-transform duration-300 ${
                isZoomed ? "scale-150" : "scale-100"
              }`}
              alt="kapoor-shop"
              style={{ cursor: isZoomed ? "zoom-out" : "zoom-in" }}
              onClick={toggleZoom}
              loading="lazy"
            />

            {/* Close Button */}
            <button
              className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-black rounded-full p-3"
              onClick={handleClose}
            >
              <IoClose size={20} />
            </button>

            {/* Conditional Navigation Buttons */}
            {activeMainIndex > 0 && (
              <button
                className="absolute bottom-4 right-1/2 transform translate-x-[calc(-100%-1.5rem)] bg-white text-black rounded-full p-3"
                onClick={handlePrev}
              >
                <FaAngleLeft size={20} />
              </button>
            )}
            {activeMainIndex < images.length - 1 && (
              <button
                className="absolute bottom-4 left-1/2 transform translate-x-[calc(100%+1.5rem)] bg-white text-black rounded-full p-3"
                onClick={handleNext}
              >
                <FaAngleRight size={20} />
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProductDetailsSlider;
