import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { RiInstagramLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  InstagramIcon,
  FacebookIcon,
  AppDownloadIcon,
  InfoIcon,
} from "../assets/SvgIcons";

const TopBar = () => {
  return (
    <div>
      <div className="bg-[#0A0304] w-full">
        <div className="w-full max-w-[1804px] mx-auto py-[9px] px-3">
          <div className="flex items-center flex-wrap gap-y-3 lg:gap-8 justify-between -mx-3">
            <ul className="flex items-center gap-1 sm:gap-3 md:gap-5 px-3">
              <li>
                <Link
                  to="https://www.facebook.com/KAPOORLEHENGASAREE"
                  target="_blank"
                  data-tooltip-id="facebookTooltip"
                >
                  <FaFacebookF style={{ color: "#1877F2" }} />
                </Link>
                <ReactTooltip
                  id="facebookTooltip"
                  place="bottom"
                  content="Facebook"
                />
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCoybOj2H8uV--DpKcaumqlw"
                  target="_blank"
                  data-tooltip-id="youtubeTooltip"
                >
                  <FaYoutube style={{ color: "#FF0000" }} />
                </Link>
                <ReactTooltip
                  id="youtubeTooltip"
                  place="right"
                  content="Youtube"
                />
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/kapoorlehengasaree/"
                  target="_blank"
                  data-tooltip-id="instagramTooltip"
                >
                  <RiInstagramLine className="gradient-icon" />
                </Link>
                <ReactTooltip
                  id="instagramTooltip"
                  place="left"
                  content="Instagram"
                />
              </li>
            </ul>
            <p className="text-[10px] sm:text-[13px] font-medium leading-[1.46] tracking-[0.26px] text-white font-jost uppercase">
              &quot;World Wide Shipping&quot;
            </p>
            <ul className="flex items-center gap-1.5 sm:gap-3 px-3">
              <li>
                <Link
                  to="https://play.google.com/store/apps/"
                  target="_blank"
                  className="flex items-center gap-2"
                >
                  <AppDownloadIcon />
                  <span className="hidden md:inline-block text-xs leading-[14px] font-normal font-Inter text-white">
                    App Download
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="flex items-center gap-2">
                  <InfoIcon />
                  <span className="hidden md:inline-block text-xs leading-[14px] font-normal font-Inter text-white">
                    Help
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
